<template>
    <div>
        <vue-final-modal 
            :drag="false"
            drag-selector=""
            v-model="showModal"
            content-class="modal-content"
            classes="modal-container"
            focus-trap>
            <button class="modal__close btn btn-light" @click="$emit('closeAddScreenModal')">
                <i class="pi pi-times"></i>
            </button>
            <span class="modal__title">Add Screen</span>
            <br>
            <div class="modal__content">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-12">
                                <p v-if="errorMessage" class="text-center text-danger auth-status-msg">{{ errorMessage }}</p>
                                <Form @submit="addScreen">
                                    <div class="form-group">
                                        <label for="name">Message</label>
                                        <Field v-model="addScreenObj.name" rules="required" type="text" name="name" class="form-control" id="name" placeholder="Name"/>
                                        <ErrorMessage name="name" class="form-text text-danger"/>
                                    </div>
                                    <label for="email">Image</label>
                                    <div class="form-group">
                                        <label v-if="screenImgPreviewUrl == null" class="form-label image-label col-12" for="idScan">
                                            <div class="card image-scan-card">
                                                <div class="card-body">
                                                    <div class="upload-text">
                                                        <h3 class="text-center"><strong><i class="pi pi-plus"></i></strong></h3>
                                                        <h4 class="text-center"><strong>Upload Image</strong></h4>
                                                    </div>
                                                    <input v-on:change="handleImageUpload()" class="d-none" ref="file" type="file" id="idScan" name="idScan">
                                                </div>
                                            </div>
                                        </label>
                                        <div v-else class="">
                                            <img class="col-12 scanned-image" :src="screenImgPreviewUrl" alt="">
                                            <br>
                                            <br>
                                            <button v-on:click="removeImage()" type="submit" class="btn text-danger remove-img-btn col-12"><small>Remove</small></button>
                                        </div>
                                    </div>
                                    <div class="row justify-content-end modal__actions">
                                        <button class="col-12 btn btn-save" :disabled="screenIsLoading ? true : false">
                                            <span v-if="screenIsLoading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                            <span v-if="!screenIsLoading">Save</span>
                                        </button>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </vue-final-modal>
    </div>
</template>
<script>
import { QuillEditor } from '@vueup/vue-quill';
import '@vueup/vue-quill/dist/vue-quill.snow.css';

import { ADD_SCREEN } from '@/store/action_types'
import { $vfm, VueFinalModal, ModalsContainer } from 'vue-final-modal'

import { Field, Form, ErrorMessage } from 'vee-validate';
import { defineRule } from 'vee-validate';
import { required, email, min } from '@vee-validate/rules';

defineRule('required', required);
defineRule('email', email);
defineRule('min', min);

export default {
    props: {
        organizationId: String
    },
    components: {
        VueFinalModal,
        ModalsContainer,
        Form,
        Field,
        ErrorMessage,
        QuillEditor
    },
    data () {
        return {
            showModal: true,
            addScreenObj: {
                name: null,
                image: null,
                org: null
            },
            screenImgPreviewUrl: null
        }
    },
    computed: {
        errorMessage() {
            return this.$store.getters.errorMsg
        },
        screen() {
              return this.$store.getters.screen.data
        },
        screenIsLoading() {
            return this.$store.getters.screen.isLoading
        }
    },
    methods: {
        setOrganization() {
            this.addScreenObj.org = this.organizationId
        },
        handleImageUpload() {
            this.addScreenObj.image = this.$refs.file.files[0];
            this.screenImgPreviewUrl = URL.createObjectURL(this.addScreenObj.image);
        },
        removeImage() {
            this.addScreenObj.image = null
            this.screenImgPreviewUrl = null
        },
        addScreen() {  
            if (!this.addScreenObj.image) {
                this.$toast.error('Please attach an image!');
                return
            }

            let formData = new FormData();
            formData.append('name', this.addScreenObj.name)
            formData.append('image', this.addScreenObj.image)
            formData.append('org', this.addScreenObj.org)

            this.$store.dispatch(ADD_SCREEN, formData).then((result) => {
                if(result) {
                    this.$emit('closeAddScreenModal')
                    this.$emit('refreshScreens')
                }
            })           
        },
    },
    created() {
        this.setOrganization()
    }
}
</script>
<style scoped>
::v-deep .modal-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30%;
    position: relative;
    left: 35%;
    margin-top: 60px;
}
::v-deep .modal-content {
    display: flex;
    flex-direction: column;
    margin: 0 1rem;
    padding: 1rem;
    border: 1px solid #e2e8f0;
    border-radius: 0.2rem;
    background: #fff;
}

.btn-save {
    margin-top: 10px;
    background-color: #14243D;
}

label {
    font-size: 13px;
}

input {
    height: 35px!important;
    font-size: 13px;
}

input::placeholder {
    font-size: 13px;
}

select {
    height: 35px!important;
    font-size: 13px;
}

select::placeholder {
    font-size: 13px;
}

.image-label {
    padding: 0px;
}

.image-scan-card {
    padding-top: 20px;
    padding-bottom: 20px;
}
</style>