<template>
    <div class="dashboard-body">
        <div class="row">
            <sidenav class="custom-col-width-2" />
            <div style="padding-left: 0px!important" class="custom-col-width-10 content-section dashboard-content">
                <navbar />
                <addMemberModal v-if="showAddMemberModal" @close-add-member-modal="showAddMemberModal = false"
                    @refresh-members="getDetails()" />
                <editMemberModal v-if="showEditMemberModal" :selectedMember="selectedMember"
                    @close-edit-member-modal="showEditMemberModal = false" @refresh-members="getMember()" />
                <deleteOrganizationModal v-if="showDeleteOrganizationModal" :selectedMemberId="selectedMemberId"
                    @close-delete-template-modal="showDeleteOrganizationModal = false"
                    @refresh-organizations="getMembers()" />
                <div class="content">
                    <br>
                    <div class="row button-grp justify-content-between">
                        <div class="col">
                            <h4>Member <small v-if="route.params.name">({{ route.params.name }})</small></h4>
                        </div>
                        <div class="col">
                            <button v-on:click="selectOrganizationToEdit()" class="btn btn-add">Edit</button>
                        </div>
                    </div>
                    <div class="row justify-content-between">
                        <memberNavbar class="" />
                    </div>
                    <br>
                    <div v-if="memberIsLoading && member == null" class="row">
                        <div class="col-4">
                            <content-loader :speed="1" :viewBox="'0 0 100 80'" :background-color="'#FFFFFF'"
                                :border-radius="'10px'" :foreground-color="'#F5F5FB'">
                                <rect x="0" y="0" rx="5" ry="5" width="100%" height="180" />
                            </content-loader>
                        </div>
                        <div class="col-4">
                            <content-loader :speed="1" :viewBox="'0 0 100 60'" :background-color="'#FFFFFF'"
                                :border-radius="'10px'" :foreground-color="'#F5F5FB'">
                                <rect x="0" y="0" rx="5" ry="5" width="100%" height="180" />
                            </content-loader>
                        </div>
                        <div class="col-4">
                            <content-loader :speed="1" :viewBox="'0 0 100 60'" :background-color="'#FFFFFF'"
                                :border-radius="'10px'" :foreground-color="'#F5F5FB'">
                                <rect x="0" y="0" rx="5" ry="5" width="100%" height="180" />
                            </content-loader>
                        </div>
                    </div>
                    <div v-else>
                        <div v-if="member" class="row">
                            <div class="col-lg-4 col-md-6 col-sm-12">
                                <div class="card info-card">
                                    <div class="card-body">
                                        <div class="card-content">
                                            <div class="row">
                                                <h5 class="header">General Info</h5>
                                            </div>
                                            <div class="row justify-content-between">
                                                <div>
                                                    <h6 class="title"><img src="../../assets/icons/person-icon.svg"
                                                            alt="">
                                                        Name</h6>
                                                </div>
                                                <div class="label">
                                                    <h6 class="value">{{ member.fname }} {{ member.lname }}</h6>
                                                </div>
                                            </div>
                                            <div class="row justify-content-between">
                                                <div>
                                                    <h6 class="title"><img src="../../assets/icons/person-icon.svg"
                                                            alt="">
                                                        Display Name</h6>
                                                </div>
                                                <div class="label">
                                                    <h6 class="value">{{ member.displayName ?? '-' }}</h6>
                                                </div>
                                            </div>
                                            <div class="row justify-content-between">
                                                <div>
                                                    <h6 class="title"><img src="../../assets/icons/person-icon.svg"
                                                            alt="">
                                                        Type</h6>
                                                </div>
                                                <div class="label">
                                                    <h6 class="value">{{ member.memberType != null ?
                                                        member.memberType.name
                                                        : '-' }}</h6>
                                                </div>
                                            </div>
                                            <div class="row justify-content-between">
                                                <div>
                                                    <h6 class="title"><img src="../../assets/icons/email-icon.svg"
                                                            alt="">
                                                        Email</h6>
                                                </div>
                                                <div class="label">
                                                    <h6 class="value">{{ member.email }}</h6>
                                                </div>
                                            </div>
                                            <div class="row justify-content-between">
                                                <div>
                                                    <h6 class="title"><img src="../../assets/icons/phone-icon.svg"
                                                            alt="">
                                                        Phone</h6>
                                                </div>
                                                <div class="label">
                                                    <h6 class="value">{{ member.phoneNumber }}</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-sm-12">
                                <div class="card info-card">
                                    <div class="card-body">
                                        <div class="card-content">
                                            <div class="row">
                                                <h5 class="header">Address</h5>
                                            </div>
                                            <div class="row justify-content-between">
                                                <div>
                                                    <h6 class="title"><img
                                                            src="../../assets/icons/location-pin-icon.svg" alt="">
                                                        {{ member.address != null ? member.address : '-' }}
                                                    </h6>
                                                </div>
                                            </div>
                                            <div class="row justify-content-between">
                                                <div>
                                                    <h6 class="title"><img src="../../assets/icons/email-icon.svg"
                                                            alt="">
                                                        City</h6>
                                                </div>
                                                <div class="label">
                                                    <h6 class="value">{{ member.city }}</h6>
                                                </div>
                                            </div>
                                            <div class="row justify-content-between">
                                                <div>
                                                    <h6 class="title"><img src="../../assets/icons/phone-icon.svg"
                                                            alt="">
                                                        State</h6>
                                                </div>
                                                <div class="label">
                                                    <h6 class="value">{{ member.state }}</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-sm-12">
                                <div class="card info-card">
                                    <div class="card-body">
                                        <div class="card-content">
                                            <div class="row">
                                                <h5 class="header">Other Info</h5>
                                            </div>
                                            <div class="row justify-content-between">
                                                <div>
                                                    <h6 class="title"><img src="../../assets/icons/person-icon.svg"
                                                            alt="">
                                                        Created At</h6>
                                                </div>
                                                <div class="label">
                                                    <h6 class="value">{{ this.$filters.getDateOnly(member.createdAt)
                                                        }}</h6>
                                                </div>
                                            </div>
                                            <div class="row justify-content-between">
                                                <div>
                                                    <h6 class="title"><img src="../../assets/icons/person-icon.svg"
                                                            alt="">
                                                        Created By</h6>
                                                </div>
                                                <div class="label">
                                                    <h6 v-if="member.user" class="value">{{ member.user.fname }}
                                                        {{
                                                        member.user.lname }}</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br>
                    <br>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { ContentLoader } from 'vue-content-loader';

import sidenav from '@/components/Sidenav.vue'
import navbar from '@/components/Navbar.vue'
import memberNavbar from '@/components/Member_Navbar.vue'
import addMemberModal from '@/components/modals/Member/Add_Member_Modal.vue'
import editMemberModal from '@/components/modals/Member/Edit_Member_Modal.vue'
import { GET_MEMBER } from '@/store/action_types'
import { ORGANIZATION_DETAILS_ROUTE, ORGANIZATION_ACCOUNTS_ROUTE, ORGANIZATION_SCREENS_ROUTE } from '@/router/routes'

export default {
    title: 'Details',
    components: {
        sidenav,
        navbar,
        memberNavbar,
        addMemberModal,
        editMemberModal,
        ContentLoader
    },
    data() {
        return {
            ORGANIZATION_DETAILS_ROUTE,
            ORGANIZATION_ACCOUNTS_ROUTE,
            ORGANIZATION_SCREENS_ROUTE,
            showAddMemberModal: false,
            showEditMemberModal: false,
            showDeleteOrganizationModal: false,
            selectedMember: null,
            selectedMemberId: null
        }
    },
    computed: {
        route() {
            return this.$route
        },
        isLoading() {
            return this.$store.getters.organizations.isLoading
        },
        organizations() {
            return this.$store.getters.organizations.data
        },
        memberIsLoading() {
            return this.$store.getters.member.isLoading
        },
        member() {
            return this.$store.getters.member.data
        }
    },
    methods: {
        isOdd(number) {
            return number % 2 !== 0;
        },
        selectOrganizationToEdit(organization) {
            this.selectedMember = this.member
            this.showEditMemberModal = true
        },
        selectOrganizationToDelete(templateId) {
            this.selectedMemberId = templateId
            this.showDeleteOrganizationModal = true
        },
        getMember() {
            this.$store.dispatch(GET_MEMBER, { id: this.$route.params.membId })
        }
    },
    mounted() {
        this.getMember()
    }
}
</script>
<style scoped>
.content .card,
.content .card-body {
    padding: 0px;
}

.content .info-card {
    margin-bottom: 20px;
}

.content .card .card-header {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background-color: #F4ECE2;
}

.content .card .header {
    font-weight: 700;
    padding-top: 10px;
    padding-bottom: 10px;
    color: #1D1F2C;
}

.content .card .card-content {
    padding: 15px 35px 15px 35px;
}

.content .card .card-content>.row {
    margin-bottom: 7px
}

.content .card .card-content .title {
    font-weight: 600;
    color: #1D1F2C;
}

.content .card .card-content .title img {
    height: 40px !important;
    margin-right: 6px;
}

.content .card .card-content .value {
    font-weight: 600;
    color: #1D1F2C;
    margin-top: 6px
}

.content .btn-add {
    height: 40px;
    float: right;
    font-size: 14px;
    border-radius: 8px;
    color: #FFFFFF !important;
    padding: 10px, 14px, 10px, 14px;
    background-color: #646F80;
    border-color: #646F80 !important;
}

.content .card table small {
    margin-left: 10px;
    margin-right: 10px;
}

.content .card table .pi-trash,
.content .card table .pi-pencil {
    cursor: pointer;
}

/*.content .organization-nav .btn {
    color: #5A5A57;
    padding: 0px;
    border-radius: 0px;
    text-decoration: unset;
    margin-left: 10px;
    font-size: 18px;
}

.content .organization-nav .btn:hover,
.content .organization-nav .btn-selected {
    padding-bottom: 5px;
    color: #cf9904;
    border-bottom: 2px solid #cf9904;
}
*/

.content .organization-nav .card-body {
    padding: 15px !important;
}

.custom-col-width-2 {
    width: 16.66%;
}

.custom-col-width-10 {
    width: 83.3%;
}

@media (max-width: 1000px) {
    .custom-col-width-2 {
        width: 12%;
    }

    .custom-col-width-10 {
        width: 88%;
    }
}
</style>