export const DASHBOARD_ROUTE = 'DASHBOARD_ROUTE'
export const DISPLAY_ROUTE = 'DISPLAY_ROUTE'
export const LOGIN_ROUTE = 'LOGIN_ROUTE'
export const DONATIONS_ROUTE = 'DONATIONS_ROUTE'
export const SYNAGOGUES_ROUTE = 'SYNAGOGUES_ROUTE'
export const SCREENS_ROUTE = 'SCREENS_ROUTE'
export const TEMPLATES_ROUTE = 'TEMPLATES_ROUTE'
export const ANNOUNCEMENTS_ROUTE = 'ANNOUNCEMENTS_ROUTE'
export const ORGANIZATIONS_ROUTE = 'ORGANIZATIONS_ROUTE'
export const ORGANIZATION_DETAILS_ROUTE = 'ORGANIZATION_DETAILS_ROUTE'
export const ORGANIZATION_ACCOUNTS_ROUTE = 'ORGANIZATION_ACCOUNTS_ROUTE'
export const ORGANIZATION_DONATIONS_ROUTE = 'ORGANIZATION_DONATIONS_ROUTE'
export const ORGANIZATION_MINYANIM_ROUTE = 'ORGANIZATION_MINYANIM_ROUTE'
export const ORGANIZATION_ANNOUNCEMENTS_ROUTE = 'ORGANIZATION_ANNOUNCEMENTS_ROUTE'
export const ORGANIZATION_SHIURIMS_ROUTE = 'ORGANIZATION_SHIURIMS_ROUTE'
export const ORGANIZATION_SCREENS_ROUTE = 'ORGANIZATION_SCREENS_ROUTE'
export const ORGANIZATION_MEMBERS_ROUTE = 'ORGANIZATION_MEMBERS_ROUTE'
export const ORGANIZATION_SYNAGOGUES_ROUTE = 'ORGANIZATION_SYNAGOGUES_ROUTE'
export const ORGANIZATION_TEMPLATES_ROUTE = 'ORGANIZATION_TEMPLATES_ROUTE'
export const ORGANIZATION_ALIASES_ROUTE = 'ORGANIZATION_ALIASES_ROUTE'
export const ORGANIZATION_INVOICES_ROUTE = 'ORGANIZATION_INVOICES_ROUTE'
export const ORGANIZATION_NOTIFICATIONS_ROUTE = 'ORGANIZATION_NOTIFICATIONS_ROUTE'
export const ORGANIZATION_SETTINGS_ROUTE = 'ORGANIZATION_SETTINGS_ROUTE'
export const MEMBERS_ROUTE = 'MEMBERS_ROUTE'
export const MEMBER_DETAILS_ROUTE = 'MEMBER_DETAILS_ROUTE'
export const MEMBER_DONATIONS_ROUTE = 'MEMBER_DONATIONS_ROUTE'
export const MEMBER_PURPOSES_ROUTE = 'MEMBER_PURPOSES_ROUTE'
export const MEMBER_SEATS_ROUTE = 'MEMBER_SEATS_ROUTE'
export const MEMBER_INVOICES_ROUTE = 'MEMBER_INVOICES_ROUTE'
export const MEMBER_INVOICE_PAYMENTS_ROUTE = 'MEMBER_INVOICE_PAYMENTS_ROUTE'
export const MEMBER_RECEIPTS_ROUTE = 'MEMBER_RECEIPTS_ROUTE'
export const MEMBER_PLEDGES_ROUTE = 'MEMBER_PLEDGES_ROUTE'
export const MEMBER_PLEDGE_ITEM_PAYMENT_LOGS_ROUTE = 'MEMBER_PLEDGE_ITEM_PAYMENT_LOGS_ROUTE'
export const DONATION_REPORT_STATS_ROUTE = 'DONATION_REPORT_STATS_ROUTE'
export const DONATION_REPORT_ROUTE = 'DONATION_REPORT_ROUTE'
export const REPORT_SEATS_ROUTE = 'REPORT_SEATS_ROUTE'
export const REPORT_PLEDGES_ROUTE = 'REPORT_PLEDGES_ROUTE'
export const ALERT_ROUTE = 'ALERT_ROUTE'
export const TICKETS_ROUTE = 'TICKETS_ROUTE'
export const ACCOUNT_ROUTE = 'ACCOUNT_ROUTE'