// account
export const SET_ACCOUNT = 'setAccount';
export const SET_SESSION_TOKEN = 'setToken';

// users
export const SET_USER = 'setUser';
export const SET_USERS = 'setUsers';

// donation
export const SET_DONATION = 'setDonation';
export const SET_DONATIONS = 'setDonations';
export const SET_DISPLAY_DONATIONS = 'setDisplayDonations';
export const SET_CALENDAR_DONATIONS = 'setCalendarDonations';
export const SET_MEMBER_DONATIONS = 'setMemberDonations';

// template
export const SET_TEMPLATE = 'setTemplate';
export const SET_TEMPLATES = 'setTemplates';

// announcement
export const SET_ANNOUNCEMENT = 'setAnnouncement';
export const SET_ANNOUNCEMENTS = 'setAnnouncements';

// screen
export const SET_SCREEN = 'setScreen';
export const SET_SCREENS = 'setScreens';
export const SET_REFRESH_SCREEN = 'setRefreshScreen';

// organization
export const SET_ORGANIZATION = 'setOrganization';
export const SET_ORGANIZATIONS = 'setOrganizations';

// member
export const SET_MEMBER = 'setMember';
export const SET_MEMBERS = 'setMembers';
export const SET_FILTERED_MEMBERS = 'setFilteredMembers';

// purpose
export const SET_PURPOSE = 'setPurpose';
export const SET_PURPOSES = 'setPurposes';
export const SET_MEMBER_PURPOSES = 'setMemberPurposes';

// notifications
export const SET_NOTIFICATION = 'setNotification';
export const SET_NOTIFICATIONS = 'setNotifications';
export const SET_ALL_NOTIFICATIONS = 'setAllNotifications';

// minyanim
export const SET_MINYANIM = 'setMinyanim';
export const SET_MINYANIMS = 'setMinyanims';
export const SET_DISPLAY_MINYANIMS = 'setDisplayMinyanims';

// room
export const SET_ROOM = 'setRoom';
export const SET_ROOMS = 'setRooms';

// alert
export const SET_ALERT = 'setAlert';
export const SET_ALERTS = 'setAlerts';

// alert_notification
export const SET_ALERT_NOTIFICATION = 'setAlertNotification';
export const SET_ALERT_NOTIFICATIONS = 'setAlertNotifications';

// ticket
export const SET_TICKET = 'setTicket';
export const SET_TICKETS = 'setTickets';

// ticket_comment
export const SET_TICKET_COMMENT = 'setTicketComment';
export const SET_TICKET_COMMENTS = 'setTicketComments';

// minyanim_type
export const SET_MINYANIM_TYPE = 'setMinyanimType';
export const SET_MINYANIM_TYPES = 'setMinyanimTypes';

// shiurim
export const SET_SHIURIM = 'setShiurim';
export const SET_SHIURIMS = 'setShiurims';

// synagogue
export const SET_SYNAGOGUE = 'setSynagogue';
export const SET_SYNAGOGUES = 'setSynagogues';

// synagogue_seat
export const SET_SYNAGOGUE_SEAT = 'setSynagogueSeat';
export const SET_SYNAGOGUE_SEATS = 'setSynagogueSeats';

// invoice
export const SET_INVOICE = 'setInvoice';
export const SET_INVOICES = 'setInvoices';
export const SET_MEMBER_INVOICES = 'setMemberInvoices';

// invoice_item
export const SET_INVOICE_ITEM = 'setInvoiceItem';
export const SET_INVOICE_ITEMS = 'setInvoiceItems';
export const SET_INVOICE_ITEM_DETAILS = 'setInvoiceItemDetails';

// invoice_item_payment_log
export const SET_INVOICE_ITEM_PAYMENT_LOG = 'setInvoiceItemPaymentLog';
export const SET_INVOICE_ITEM_PAYMENT_LOGS = 'setInvoiceItemPaymentLogs';
export const SET_SINGLE_INVOICE_ITEM_PAYMENT_LOGS = 'setSingleInvoiceItemPaymentLogs';

// synagogue_seat_price_category
export const SET_SYNAGOGUE_SEAT_PRICE_CATEGORY = 'setSynagogueSeatPriceCategory';
export const SET_SYNAGOGUE_SEAT_PRICE_CATEGORIES = 'setSynagogueSeatPriceCategories';

// synagogue_seat_pricing
export const SET_SYNAGOGUE_SEAT_PRICING = 'setSynagogueSeatPricing';
export const SET_SYNAGOGUE_SEAT_PRICINGS = 'setSynagogueSeatPricings';

// synagogue_member_seat
export const SET_SYNAGOGUE_MEMBER_SEAT = 'setSynagogueMemberSeat';
export const SET_SYNAGOGUE_MEMBER_SEATS = 'setSynagogueMemberSeats';

// receipt
export const SET_RECEIPT = 'setReceipt';
export const SET_RECEIPTS = 'setReceipts';
export const SET_MEMBER_RECEIPTS = 'setMemberReceipts';

// member_type
export const SET_MEMBER_TYPE = 'setMemberType';
export const SET_MEMBER_TYPES = 'setMemberTypes';

// pledge_item
export const SET_PLEDGE_ITEM = 'setPledgeItem';
export const SET_PLEDGE_ITEMS = 'setPledgeItems';

// organization_screen
export const SET_ORGANIZATION_SCREEN = 'setOrganizationScreen';
export const SET_ORGANIZATION_SCREENS = 'setOrganizationScreens';

// pledge_item_payment_log
export const SET_PLEDGE_ITEM_PAYMENT_LOG = 'setPledgeItemPaymentLog';
export const SET_PLEDGE_ITEM_PAYMENT_LOGS = 'setPledgeItemPaymentLogs';

// report
export const SET_DONATION_REPORT_STATS = 'setDonationReportStats';
export const SET_DONATION_REPORT = 'setDonationReport';
export const SET_DONATION_REPORT_FILE = 'setDonationReportFile';
export const SET_DONATION_REPORT_FILES = 'setDonationReportFiles';
export const SET_MEMBER_STATEMENT_REPORT_FILE = 'setMemberStatementReportFile';
export const SET_MEMBER_STATEMENT_REPORT_FILES = 'setMemberStatementReportFiles';

// report_file
export const SET_REPORT_FILE = 'setReportFile';
export const SET_REPORT_FILES = 'setReportFiles';

// pledge_item_deletion_reason
export const SET_PLEDGE_ITEM_DELETION_REASON = 'setPledgeItemDeletionReason';
export const SET_PLEDGE_ITEM_DELETION_REASONS = 'setPledgeItemDeletionReasons';

// pledge_item_edit_reason
export const SET_PLEDGE_ITEM_EDIT_REASON = 'setPledgeItemEditReason';
export const SET_PLEDGE_ITEM_EDIT_REASONS = 'setPledgeItemEditReasons';

// pledge_type
export const SET_PLEDGE_TYPE = 'setPledgeType';
export const SET_PLEDGE_TYPES = 'setPledgeTypes';

// member_statement
export const SET_MEMBER_STATEMENT_FILE = 'setMemberStatement';
export const SET_MEMBER_STATEMENT_FILES = 'setMemberStatements';

// module_setting
export const SET_MODULE_SETTING = 'setModuleSetting';
export const SET_MODULE_SETTINGS = 'setModuleSettings';

// alias
export const SET_ALIAS = 'setAlias';
export const SET_ALIASES = 'setAliases';

// stats
export const SET_DASHBOARD_STATS = 'setDashboardStats';

// router_loader
export const SET_ROUTER_LOADER = 'setRouterLoader';

// error
export const SET_ERRORMSG = 'setErrorMsg';