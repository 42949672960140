<template>
    <div class="dashboard-body">
        <div class="row">
            <sidenav class="custom-col-width-2" />
            <div style="padding-left: 0px!important" class="custom-col-width-10 content-section dashboard-content">
                <navbar />
                <div class="content">
                    <registerPledgeModal v-if="showRegisterPledgeModal" :organizationId="organizationId"
                        :memberId="memberId" :hasCredit="hasCredit"
                        @close-register-pledge-modal="showRegisterPledgeModal = false"
                        @refresh-pledges="getPledgeItems()" />
                    <editPledgeModal v-if="showEditPledgeModal" :selectedPledgeItem="selectedPledgeItem"
                        @close-edit-pledge-modal="showEditPledgeModal = false" @refresh-pledges="getPledgeItems()" />
                    <payPledgeModal v-if="showPayPledgeItemModal" :selectedMemberId="selectedMemberId"
                        :selectedPledgeItem="selectedPledgeItem"
                        @close-pay-pledge-item-modal="showPayPledgeItemModal = false"
                        @refresh-pledge-items="getPledgeItems()" />
                    <deletePledgeModal v-if="showDeletePledgeModal" :selectedPledgeItemId="selectedPledgeItemId"
                        @close-delete-pledge-item-modal="showDeletePledgeModal = false"
                        @refresh-pledge-items="getPledgeItems()" />
                    <pledgeItemDeletionReasonModal v-if="showPledgeItemDeletionReasonModal"
                        :pledgeItemDeletionReason="pledgeItemDeletionReason"
                        @close-pledge-item-deletion-reason-modal="showPledgeItemDeletionReasonModal = false" />
                    <pledgeItemEditReasonModal v-if="showPledgeItemEditReasonModal"
                        :pledgeItemEditReasons="pledgeItemEditReasons"
                        @close-pledge-item-edit-reason-modal="showPledgeItemEditReasonModal = false" />
                    <memberStatementFilesModal v-if="showMemberStatementFilesModal"
                        @close-member-statement-files-modal="showMemberStatementFilesModal = false" />
                    <generateMemberStatementModal v-if="showGenerateMemberStatementModal"
                        @close-generate-member-statement-modal="showGenerateMemberStatementModal = false" />
                    <br>
                    <div class="row button-grp justify-content-between">
                        <div class="col">
                            <h4>Member <small v-if="route.params.name">({{ route.params.name }})</small></h4>
                        </div>
                        <div class="col">
                            <button v-on:click="showRegisterPledgeModal = true" class="btn btn-add"><i
                                    class="pi pi-plus"></i> New Pledge</button>
                            <button v-on:click="showGenerateMemberStatementModal = true"
                                class="btn btn-generate-statement"><i class="pi pi-print"></i> Generate
                                Statement
                            </button>
                            <font-awesome-icon v-on:click="showMemberStatementFilesModal = true"
                                icon="fa-solid fa-clock-rotate-left" class="fa" style="float: right" />
                        </div>
                    </div>
                    <div class="row justify-content-between">
                        <memberNavbar class="" />
                    </div>
                    <div v-if="pledgeItems">
                        <div v-if="pledgeItems.pledgeItems" class="row justify-content-between pledge-payment-stats">
                            <div class="col-2">
                                <router-link :to="{ name: MEMBER_PLEDGE_ITEM_PAYMENT_LOGS_ROUTE }"
                                    class="btn btn-view-payments">View Payments</router-link>
                            </div>
                            <div class="col-10 payment-stat-figures">
                                <div class="row justify-content-end">
                                    <div class="">
                                        <!-- <h5>Payments: <span class="unpaid text-primary">{{
                                                $filters.formatPrice(pledgeItems.totalAmount,
                                                'USD') }}</span> |
                                            <span class="paid">{{ $filters.formatPrice(pledgeItems.totalAmountPaid,
                                                'USD') }}</span> |
                                            <span class="credit">{{ $filters.formatPrice((pledgeItems.totalAmountPaid - pledgeItems.totalAmount) > 0 ? (pledgeItems.totalAmountPaid - pledgeItems.totalAmount) : 0,
                                                'USD') }}</span> |
                                            <span class="unpaid">{{ $filters.formatPrice(pledgeItems.totalAmount -
                                                pledgeItems.totalAmountPaid > 0 ? pledgeItems.totalAmount -
                                                pledgeItems.totalAmountPaid : 0, 'USD') }}</span>
                                        </h5> -->
                                        <h5>
                                            <div class="financial-summary">
                                                <div class="amount-section">
                                                    <span class="amount unpaid text-primary">
                                                        {{ $filters.formatPrice(pledgeItems.totalAmount, 'USD') }}
                                                    </span>
                                                    <span class="description">Total Amount</span>
                                                </div> |
                                                <div class="amount-section">
                                                    <span class="amount paid">
                                                        {{ $filters.formatPrice((pledgeItems.totalPaidCreditableAmount +
                                                        pledgeItems.totalPaidNonCreditableAmount), 'USD') }}
                                                    </span>
                                                    <span class="description">Amount Paid</span>
                                                </div> |
                                                <div class="amount-section">
                                                    <span class="amount credit">
                                                        {{
                                                        $filters.formatPrice(calculateCreditAmount(pledgeItems.totalCreditableAmount,
                                                        pledgeItems.totalPaidCreditableAmount) > 0 ?
                                                        calculateCreditAmount(pledgeItems.totalCreditableAmount,
                                                        pledgeItems.totalPaidCreditableAmount) : 0, 'USD') }}
                                                    </span>
                                                    <!-- <span class="amount credit">
                                                        {{ $filters.formatPrice((pledgeItems.totalAmountPaid - pledgeItems.totalPaidCreditableAmount) > 0 ?
                                                            (pledgeItems.totalAmountPaid - pledgeItems.totalPaidCreditableAmount) : 0, 'USD') }}
                                                    </span> -->
                                                    <span class="description">Credit</span>
                                                </div> |
                                                <div class="amount-section">
                                                    <span class="amount unpaid">
                                                        {{
                                                        $filters.formatPrice(calculateTotalUnpaidAmount(pledgeItems.totalCreditableAmount,
                                                        pledgeItems.totalPaidCreditableAmount,
                                                        pledgeItems.totalNonCreditableAmount,
                                                        pledgeItems.totalPaidNonCreditableAmount), 'USD') }}
                                                    </span>
                                                    <span class="description">Unpaid</span>
                                                </div>
                                            </div>
                                        </h5>
                                    </div>
                                    <div class="">
                                        <button v-on:click="showPayPledgeItemModal = true"
                                            class="btn btn-pay">PAY</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="pledgeItemsIsLoading && pledgeItems == null">
                        <div class="row">
                            <div class="col-12 shimmer-loader-row">
                                <content-loader :speed="1" :viewBox="'0 0 100 3'" :background-color="'#FFFFFF'"
                                    :border-radius="'1px'" :foreground-color="'#DFDFFAFF'">
                                    <rect x="0" y="0" rx="0" ry="0" width="100%" height="18" />
                                </content-loader>
                            </div>
                            <br>
                            <div class="col-12 shimmer-loader-row">
                                <content-loader :speed="1" :viewBox="'0 0 100 3'" :background-color="'#FFFFFF'"
                                    :border-radius="'1px'" :foreground-color="'#F5F5FB'">
                                    <rect x="0" y="0" rx="0" ry="0" width="100%" height="18" />
                                </content-loader>
                            </div>
                            <br>
                            <div class="col-12 shimmer-loader-row">
                                <content-loader :speed="1" :viewBox="'0 0 100 3'" :background-color="'#FFFFFF'"
                                    :border-radius="'1px'" :foreground-color="'#F5F5FB'">
                                    <rect x="0" y="0" rx="0" ry="0" width="100%" height="18" />
                                </content-loader>
                            </div>
                            <br>
                            <div class="col-12 shimmer-loader-row">
                                <content-loader :speed="1" :viewBox="'0 0 100 3'" :background-color="'#FFFFFF'"
                                    :border-radius="'1px'" :foreground-color="'#F5F5FB'">
                                    <rect x="0" y="0" rx="0" ry="0" width="100%" height="18" />
                                </content-loader>
                            </div>
                            <br>
                            <div class="col-12 shimmer-loader-row">
                                <content-loader :speed="1" :viewBox="'0 0 100 3'" :background-color="'#FFFFFF'"
                                    :border-radius="'1px'" :foreground-color="'#F5F5FB'">
                                    <rect x="0" y="0" rx="0" ry="0" width="100%" height="18" />
                                </content-loader>
                            </div>
                            <br>
                            <div class="col-12 shimmer-loader-row">
                                <content-loader :speed="1" :viewBox="'0 0 100 3'" :background-color="'#FFFFFF'"
                                    :border-radius="'1px'" :foreground-color="'#F5F5FB'">
                                    <rect x="0" y="0" rx="0" ry="0" width="100%" height="18" />
                                </content-loader>
                            </div>
                            <br>
                            <div class="col-12 shimmer-loader-row">
                                <content-loader :speed="1" :viewBox="'0 0 100 3'" :background-color="'#FFFFFF'"
                                    :border-radius="'1px'" :foreground-color="'#F5F5FB'">
                                    <rect x="0" y="0" rx="0" ry="0" width="100%" height="18" />
                                </content-loader>
                            </div>
                            <br>
                            <div class="col-12 shimmer-loader-row">
                                <content-loader :speed="1" :viewBox="'0 0 100 3'" :background-color="'#FFFFFF'"
                                    :border-radius="'1px'" :foreground-color="'#F5F5FB'">
                                    <rect x="0" y="0" rx="0" ry="0" width="100%" height="18" />
                                </content-loader>
                            </div>
                            <br>
                            <div class="col-12 shimmer-loader-row">
                                <content-loader :speed="1" :viewBox="'0 0 100 3'" :background-color="'#FFFFFF'"
                                    :border-radius="'1px'" :foreground-color="'#F5F5FB'">
                                    <rect x="0" y="0" rx="0" ry="0" width="100%" height="18" />
                                </content-loader>
                            </div>
                        </div>
                    </div>
                    <div v-else class="row">
                        <div class="col-lg-12">
                            <div class="card">
                                <div v-if="pledgeItems" class="card-body">
                                    <table v-if="pledgeItems.pledgeItems" class="table">
                                        <thead>
                                            <tr>
                                                <th scope="col">#</th>
                                                <th scope="col">Name</th>
                                                <th scope="col">Price</th>
                                                <th scope="col">Quantity</th>
                                                <th scope="col">Total</th>
                                                <th scope="col">Status</th>
                                                <th scope="col">Payment</th>
                                                <th scope="col">Pledge Date</th>
                                                <th scope="col">Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <template v-bind:key="index"
                                                v-for="(item, index) in pledgeItems.pledgeItems">
                                                <tr
                                                    :class="[item.isDeleted ? 'deleted-row' : isOdd(index + 1) ? 'striped' : null]">
                                                    <th scope="row">{{ index + 1 }}</th>
                                                    <td><span v-if="item.pledgeType">{{ item.pledgeType.name }}</span>
                                                    </td>
                                                    <td>{{ $filters.formatPrice(item.price, 'USD') }} </td>
                                                    <td>{{ item.quantity }} </td>
                                                    <td>{{ $filters.formatPrice(item.price * item.quantity, 'USD') }}
                                                    </td>
                                                    <td>
                                                        <span
                                                            v-if="(item.isDeleted && item.pledgeItemDeletionReason != null)">
                                                            <span class=""><span
                                                                    class="text-danger"><strong>DELETED</strong></span><i
                                                                    v-on:click="selectPledgeItemDeletionReason(item.pledgeItemDeletionReason.reason)"
                                                                    class="pi pi-info-circle"></i></span>
                                                        </span>
                                                        <span v-else>
                                                            <span v-if="item.totalAmountPaid == 0"
                                                                class="badge badge-danger">- {{
                                                                $filters.formatPrice(item.price * item.quantity, 'USD')
                                                                }}</span>
                                                            <span
                                                                v-if="item.totalAmountPaid > 0 && (item.totalAmountPaid < (item.price * item.quantity))"
                                                                class="badge badge-warning"> - {{
                                                                $filters.formatPrice((item.price * item.quantity) -
                                                                item.totalAmountPaid, 'USD') }}</span>
                                                            <span
                                                                v-if="item.totalAmountPaid == (item.price * item.quantity)"
                                                                class="badge badge-success"> + {{
                                                                $filters.formatPrice(item.totalAmountPaid, 'USD')
                                                                }}</span>
                                                            <span v-if="item.pledgeItemEditReasons">
                                                                <i v-if="item.pledgeItemEditReasons.length > 0"
                                                                    v-on:click="selectPledgeItemEditReason(item.pledgeItemEditReasons)"
                                                                    class="pi pi-info-circle"></i>
                                                            </span>
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <button v-on:click="selectPledgeItemToPay(item)"
                                                            class="btn btn-pay-pledge-item">PAY</button>
                                                    </td>
                                                    <td><span class="frankRuhl-font">{{
                                                            $filters.convertToHebrewDate(item.pledgeDate) }}</span></td>
                                                    <td>
                                                        <span v-if="(!item.isDeleted)">
                                                            <i v-on:click="selectPledgeItemToEdit(item)"
                                                                class="pi pi-pencil text-primary"></i>
                                                            <i v-on:click="selectPledgeToDelete(item.id)"
                                                                class="pi pi-trash text-danger"></i>
                                                        </span>
                                                    </td>
                                                </tr>
                                            </template>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br>
                    <br>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { ContentLoader } from 'vue-content-loader';

import sidenav from '@/components/Sidenav.vue'
import navbar from '@/components/Navbar.vue'
import payPledgeModal from '@/components/modals/Pledge/Pay_Pledge_Modal.vue'
import memberNavbar from '@/components/Member_Navbar.vue'
import registerPledgeModal from '@/components/modals/Pledge/Register_Pledge_Item_Modal.vue'
import editPledgeModal from '@/components/modals/Pledge/Edit_Pledge_Item_Modal.vue'
import deletePledgeModal from '@/components/modals/Pledge/Delete_Pledge_Item_Modal.vue'
import pledgeItemDeletionReasonModal from '@/components/modals/Pledge/Pledge_Item_Deletion_Reason_Modal.vue'
import pledgeItemEditReasonModal from '@/components/modals/Pledge/Pledge_Item_Edit_Reason_Modal.vue'
import memberStatementFilesModal from '@/components/modals/Member_Statement/Member_Statement_Files_Modal.vue'
import generateMemberStatementModal from '@/components/modals/Member_Statement/Generate_Member_Statement_Modal.vue'
import { STORAGE_URL } from '@/utils/const'
import { MEMBER_PLEDGE_ITEM_PAYMENT_LOGS_ROUTE } from '@/router/routes'
import { GENERATE_MEMBER_STATEMENT_FILE, GET_PLEDGE_ITEMS } from '@/store/action_types';
import { PAYMENT_METHODS } from '@/utils/const';

import { library } from '@fortawesome/fontawesome-svg-core'
import { faClockRotateLeft } from '@fortawesome/free-solid-svg-icons'
import { } from '@fortawesome/free-regular-svg-icons'
library.add(faClockRotateLeft)

export default {
    title: 'Members',
    components: {
        sidenav,
        navbar,
        payPledgeModal,
        registerPledgeModal,
        editPledgeModal,
        deletePledgeModal,
        pledgeItemDeletionReasonModal,
        pledgeItemEditReasonModal,
        memberNavbar,
        memberStatementFilesModal,
        generateMemberStatementModal,
        ContentLoader
    },
    data() {
        return {
            MEMBER_PLEDGE_ITEM_PAYMENT_LOGS_ROUTE,
            PAYMENT_METHODS,
            showRegisterPledgeModal: false,
            showEditPledgeModal: false,
            showPledgeItemsModal: false,
            showDeletePledgeModal: false,
            showMemberPurposeModal: false,
            showPledgeItemDeletionReasonModal: false,
            showPledgeItemEditReasonModal: false,
            showMemberStatementFilesModal: false,
            pledgeItemDeletionReason: null,
            pledgeItemEditReasons: null,
            selectedMember: null,
            selectedMemberId: null,
            selectedPledgeItem: null,
            selectedPledgeItemId: null,
            showPayPledgeModal: null,
            organizationId: null,
            memberId: null,
            showPayPledgeItemModal: false,
            showGenerateMemberStatementModal: false,
            selectedPledgeItem: null,
            hasCredit: null
        }
    },
    computed: {
        route() {
            return this.$route
        },
        pledgeItems() {
            return this.$store.getters.pledgeItems.data
        },
        pledgeItemsIsLoading() {
            return this.$store.getters.pledgeItems.isLoading
        },
        memberStatementFile() {
            return this.$store.getters.memberStatementFile.data
        },
        memberStatementFileIsLoading() {
            return this.$store.getters.memberStatementFile.isLoading
        },
        account() {
            return this.$store.getters.account.data
        }
    },
    methods: {
        isOdd(number) {
            return number % 2 !== 0;
        },
        openFile(file) {
            window.open(`${STORAGE_URL}/${file}`, '_blank');
        },
        checkIfMemberHasCredit() {
            if (this.pledgeItems.totalAmountPaid > this.pledgeItems.totalAmount) {
                this.hasCredit = true
            }
        },
        calculateTotalUnpaidAmount(totalCreditableAmount, totalPaidCreditableAmount, totalNonCreditableAmount, totalPaidNonCreditableAmount) {
            // if ((totalAmount - totalAmountPaid) > 0) {
            //     return (totalAmount - totalAmountPaid) + totalUnpaidNonCreditableAmount
            // } else {
            //     return totalUnpaidNonCreditableAmount
            // }
            // let totalUnpaid = totalAmount - (totalPaidCreditableAmount + totalPaidNonCreditableAmount)
            // let totalUsableUnpaidNonCreditableAmount = totalUnpaidNonCreditableAmount > 0 ? totalUnpaidNonCreditableAmount : 0

            // if(totalUnpaid > 0) {
            //     return totalUsableUnpaidNonCreditableAmount + totalUnpaid
            // } else {
            //     return totalUsableUnpaidNonCreditableAmount
            // }
            let netCreditableAmount = (totalCreditableAmount - totalPaidCreditableAmount) > 0 ? (totalCreditableAmount - totalPaidCreditableAmount) : 0
            let netNonCreditableAmount = (totalNonCreditableAmount - totalPaidNonCreditableAmount) > 0 ? (totalNonCreditableAmount - totalPaidNonCreditableAmount) : 0
            return (netCreditableAmount) + (netNonCreditableAmount)
        },
        calculateCreditAmount(totalCreditableAmount, totalPaidCreditableAmount) {
            // if ((totalAmount - totalAmountPaid) >= 0) {
            //     return 0
            // } else {
            //     return totalAmountPaid - totalAmount
            // }
            return totalPaidCreditableAmount - totalCreditableAmount
        },
        selectPledgeItemDeletionReason(reason) {
            this.pledgeItemDeletionReason = reason;
            this.showPledgeItemDeletionReasonModal = true
        },
        selectPledgeItemEditReason(reason) {
            this.pledgeItemEditReasons = reason;
            this.showPledgeItemEditReasonModal = true
        },
        setValues() {
            this.organizationId = this.$route.params.orgId
            this.memberId = this.$route.params.membId
            this.selectedMemberId = this.$route.params.membId
        },
        selectPledgeItemToEdit(pledgeItem) {
            this.selectedPledgeItem = pledgeItem
            this.showEditPledgeModal = true
        },
        selectPledgeItemToPay(pledgeItem) {
            this.selectedPledgeItem = pledgeItem
            this.showPayPledgeItemModal = true
        },
        selectPledgeToDelete(id) {
            this.selectedPledgeItemId = id
            this.showDeletePledgeModal = true
        },
        selectPledgePurpose(member) {
            this.selectedMember = member
            this.showMemberPurposeModal = true
        },
        toggleMemberIsActive(id) {
            this.$store.dispatch(TOGGLE_MEMBER_IS_ACTIVE, { id })
        },
        getPledgeItems() {
            this.$store.dispatch(GET_PLEDGE_ITEMS, { memb: this.$route.params.membId }).then((res) => {
                this.checkIfMemberHasCredit()
            })
        }
    },
    mounted() {
        this.setValues()
        this.getPledgeItems()
    }
}
</script>
<style scoped>
.content .card,
.content .card-body {
    padding: 0px;
}

.button-grp .fa-clock-rotate-left {
    padding: 5px;
    padding-right: 10px;
    position: relative;
    top: 6px;
    right: 15px;
    border-right: 1px solid #A3A2A2FF;
    cursor: pointer;
}

.payment-figure {
    float: right;
}

.unpaid {
    color: #dc3545;
    margin-left: 5px;
    margin-right: 5px;
}

.paid {
    color: #1A9882;
    margin-left: 5px;
    margin-right: 5px;
}

.credit {
    color: #17a2b8;
    margin-left: 5px;
    margin-right: 5px;
}

.btn-pay {
    color: #5E84C2;
    font-weight: 700;
    letter-spacing: 0.7px;
    background-color: #E4EBFC;
    border: 1px solid #CCDCF7;
    position: relative;
    bottom: 7px;
    left: 15px;
}

.btn-back {
    font-weight: 600;
    color: #5D84C2;
    position: relative;
    top: 10px;
}

.btn-back .pi {
    font-size: 13px;
    margin-right: 6px;
}

.btn-generate-statement {
    float: right;
    font-size: 14px;
    border-radius: 8px;
    margin-right: 15px;
    color: #FFFFFF !important;
    background-color: #646F80;
    border-color: #646F80 !important;
}

.btn-generate-statement .pi {
    margin-right: 3px;
}

.status-container {
    display: flex;
    align-items: center;
    padding: 0px;
    color: #000000;
    font-size: 13px;
}

.status-active-bullet {
    width: 9px;
    height: 9px;
    background-color: green;
    border-radius: 50%;
    animation: blink 1s infinite;
    margin-right: 10px;
}

.status-inactive-bullet {
    width: 9px;
    height: 9px;
    background-color: red;
    border-radius: 50%;
    /*animation: blink 1s infinite;*/
    margin-right: 10px;
}

.status-pending-bullet {
    width: 9px;
    height: 9px;
    background-color: #6c757d;
    border-radius: 50%;
    margin-right: 10px;
}

.pledge-payment-stats {
    margin-top: 5px;
    margin-bottom: 5px;
}

.payment-stat-figures {
    padding-right: 50px;
}

.btn-pay-pledge-item {
    font-size: 14px;
    color: #5E84C2;
    font-weight: 700;
    letter-spacing: 0.7px;
    background-color: transparent;
    position: relative;
    bottom: 4px;
}

.btn-view-payments {
    font-weight: 600;
    color: #5D84C2;
    position: relative;
}

.table .pi-info-circle {
    font-size: 12px;
    cursor: pointer;
    margin-left: 5px;
    position: relative;
    top: 1px;
}

.financial-summary {
    display: flex;
    justify-content: center;
    font-size: 16px;
    border-radius: 5px;
    position: relative;
    bottom: 7px;

}

.amount-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 10px;
}

.amount-section .amount {
    font-size: 18px;
}

.amount-section .description {
    font-size: 12px;
    color: #6c757d;
}

.unpaid {
    color: #dc3545;
}

.paid {
    color: #28a745;
}

.text-primary {
    font-weight: bold;
}

.custom-col-width-2 {
    width: 16.66%;
}

.custom-col-width-10 {
    width: 83.3%;
}

@media (max-width: 1000px) {
    .custom-col-width-2 {
        width: 12%;
    }

    .custom-col-width-10 {
        width: 88%;
    }
}
</style>