<template>
    <div>
        <vue-final-modal :drag="false" drag-selector="" v-model="showModal" content-class="modal-content"
            classes="modal-container" focus-trap>
            <button class="modal__close btn btn-light" @click="$emit('closePledgeItemEditReasonModal')">
                <i class="pi pi-times"></i>
            </button>
            <span class="modal__title">Pledge Item Edit Reasons</span>
            <br>
            <div class="modal__content">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-12">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th scope="col">#</th>
                                            <th scope="col">Reason</th>
                                            <th scope="col">Pledge Item</th>
                                            <th scope="col">Quantity</th>
                                            <th scope="col">Price</th>
                                            <th scope="col">Changed On</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <template v-bind:key="index"
                                            v-for="(editReason, index) in pledgeItemEditReasons">
                                            <tr :class="[isOdd(index + 1) ? 'striped' : null]">
                                                <th scope="row">{{ index + 1 }}</th>
                                                <td>{{ editReason.reason }}</td>
                                                <td>
                                                    <span v-if="editReason.oldPledgeType.id != editReason.newPledgeType.id"><span class="text-danger">{{ editReason.oldPledgeType.name }}</span> <small>to</small> <span class="text-success">{{ editReason.newPledgeType.name }}</span></span>
                                                    <span v-else>{{ editReason.oldPledgeType.name }}</span>
                                                </td>
                                                <td>
                                                    <span v-if="editReason.oldQuantity != editReason.newQuantity"><span class="text-danger">{{ editReason.oldQuantity }}</span> <small>to</small> <span class="text-success">{{ editReason.newQuantity }}</span></span>
                                                    <span v-else>{{ editReason.oldQuantity }}</span>

                                                </td>
                                                <td>
                                                    <span v-if="editReason.oldPrice != editReason.newPrice"><span class="text-danger">{{ $filters.formatPrice(editReason.oldPrice, 'USD') }}</span> <small>to</small> <span class="text-success">{{ $filters.formatPrice(editReason.newPrice, 'USD') }}</span></span>
                                                    <span v-else>{{ editReason.oldPrice }}</span>
                                                </td>
                                                <td>{{ $filters.getDateOnly(editReason.createdAt) }}</td>
                                            </tr>
                                        </template>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </vue-final-modal>
    </div>
</template>
<script>
import { $vfm, VueFinalModal, ModalsContainer } from 'vue-final-modal'

import { Field, Form, ErrorMessage } from 'vee-validate';
import { defineRule } from 'vee-validate';
import { required, email, min } from '@vee-validate/rules';

defineRule('required', required);
defineRule('email', email);
defineRule('min', min);

export default {
    props: {
        pledgeItemEditReasons: String
    },
    components: {
        VueFinalModal,
        ModalsContainer,
        Form,
        Field,
        ErrorMessage
    },
    data() {
        return {
            showModal: true
        }
    },
    computed: {
        errorMessage() {
            return this.$store.getters.errorMsg
        },
        pledgeItem() {
            return this.$store.getters.pledgeItem.data
        },
        pledgeItemIsLoading() {
            return this.$store.getters.pledgeItem.isLoading
        }
    },
    methods: {
        isOdd(number) {
            return number % 2 !== 0;
        }
    }
}
</script>
<style scoped>
::v-deep .modal-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60%;
    position: relative;
    left: 20%;
    margin-top: 50px;
}

::v-deep .modal-content {
    display: flex;
    flex-direction: column;
    margin: 0 1rem;
    padding: 1rem;
    border: 1px solid #e2e8f0;
    border-radius: 0.2rem;
    background: #fff;
}

.modal__title {
    font-size: 18px;
    font-weight: 600;
}

.modal__close {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
}

.modal__content .nav-tabs .nav-link {
    font-size: 15px !important;
}

.modal__content .table small {
    margin-left: 5px;
    margin-right: 5px;
    position: relative;
    bottom: 1px;
}

button {
    font-size: 15px;
}
</style>