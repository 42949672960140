<template>
    <div>
        <vue-final-modal :drag="false" drag-selector="" v-model="showModal" content-class="modal-content"
            classes="modal-container" focus-trap>
            <button class="modal__close btn btn-light" @click="$emit('closeSynagogueSeatPriceModal')">
                <i class="pi pi-times"></i>
            </button>
            <span class="modal__title">Set Seat Prices</span>
            <div class="modal__content">
                <editSynagogueModal v-if="showEditSynagogueModal" :selectedSynagogue="selectedSynagogue"
                    @close-edit-synagogue-modal="showEditSynagogueModal = false"
                    @refresh-synagogues="getSynagogues()" />
                <addSeatPriceModal v-if="showAddSeatPriceModal" :organizationId="organizationId"
                    @close-add-seat-price-modal="showAddSeatPriceModal = false" />
                <p v-if="errorMessage" class="text-center text-danger auth-status-msg">{{ errorMessage }}</p>
                <div class="card">
                    <div class="card-body">
                        <Form @submit="saveSynagogueSeatPricing">
                            <div class="row">
                                <div class="col-12">
                                    <div class="row justify-content-between">
                                        <div class="col">
                                            <div class="dropdown seat-price-dropdown">
                                                <button class="btn btn-secondary dropdown-toggle" type="button"
                                                    :style="selectedSynagogueSeatPriceCategory != null ? `color: ${selectedSynagogueSeatPriceCategory.colorHex}; border: 2px solid ${selectedSynagogueSeatPriceCategory.colorHex}; background-color: transparent; font-weight: 600;` : null"
                                                    id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true"
                                                    aria-expanded="false">
                                                    {{ selectedSynagogueSeatPriceCategory != null ?
                                                    $filters.formatPrice(selectedSynagogueSeatPriceCategory.price,
                                                    'USD') : 'Select a price' }}
                                                </button>
                                                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                    <template v-bind:key="index"
                                                        v-for="(priceCategory, index) in synagogueSeatPriceCategories">
                                                        <a class="dropdown-item"
                                                            :style="`color: ${priceCategory.colorHex}`"
                                                            v-on:click="selectPriceCategory(priceCategory)">{{
                                                            $filters.formatPrice(priceCategory.price, 'USD') }}</a>
                                                    </template>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-auto zoom-controls">
                                            <button @click="zoomIn" class="btn"><font-awesome-icon
                                                    icon="fa-solid fa-magnifying-glass-plus" class="fa" /></button>
                                            <button @click="zoomOut" class="btn"><font-awesome-icon
                                                    icon="fa-solid fa-magnifying-glass-minus" class="fa" /></button>
                                        </div>
                                        <div class="col-md-auto">
                                            <button v-on:click="addSynagogeSeatPrice()" class="btn btn-add">Add
                                                Price Category</button>
                                        </div>
                                    </div>
                                    <div id="seats" class="">

                                        <h6 v-if="synagogueSeatsIsLoading" class="text-center">Loading...</h6>
                                        <div v-else :style="zoomStyle">
                                            <div v-for="(row, rowIndex) in matrix" :key="rowIndex" class="row">
                                                <div class="col p-1" v-for="(value, colIndex) in row" :key="colIndex">
                                                    <button v-on:click="toggleSelection(rowIndex, colIndex)"
                                                        type="button" class="btn btn-block"
                                                        :class="{ 'btn-seat-selected': isSelected(rowIndex, colIndex), 'btn-seat-not-selected': !isSelected(rowIndex, colIndex) }"
                                                        :style="`background-color: ${selectedColor(rowIndex, colIndex)}`">
                                                        {{ value }}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr>
                            <div class="row justify-content-end modal__actions">
                                <button class="col-1 btn btn-save"
                                    :disabled="synagogueSeatPricingIsLoading ? true : false">
                                    <span v-if="synagogueSeatPricingIsLoading" class="spinner-border spinner-border-sm"
                                        role="status" aria-hidden="true"></span>
                                    <span v-if="!synagogueSeatPricingIsLoading"> Save</span>
                                </button>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </vue-final-modal>
    </div>
</template>
<script>
import editSynagogueModal from '@/components/modals/Synagogue/Edit_Synagogue_Modal.vue'
import addSeatPriceModal from '@/components/modals/Synagogue/Pricing/Add_New_Price.vue'

import { ADD_SYNAGOGUE_SEAT_PRICING, DELETE_SCREEN, GET_SYNAGOGUE_SEATS, GET_SYNAGOGUE_SEAT_PRICE_CATEGORIES, GET_SYNAGOGUE_SEAT_PRICINGS } from '@/store/action_types'
import { $vfm, VueFinalModal, ModalsContainer } from 'vue-final-modal'

import { Field, Form, ErrorMessage } from 'vee-validate';
import { defineRule } from 'vee-validate';
import { required, email, min } from '@vee-validate/rules';
import { SEATS_MATRIX, TOAST_GLOBAL_CONFIG } from '@/utils/const';

import { library } from '@fortawesome/fontawesome-svg-core'
import { faMagnifyingGlassPlus, faMagnifyingGlassMinus } from '@fortawesome/free-solid-svg-icons'
import { } from '@fortawesome/free-regular-svg-icons'
library.add(faMagnifyingGlassPlus, faMagnifyingGlassMinus)

defineRule('required', required);
defineRule('email', email);
defineRule('min', min);

export default {
    props: {
        selectedSynagogue: String
    },
    components: {
        editSynagogueModal,
        addSeatPriceModal,
        VueFinalModal,
        ModalsContainer,
        Form,
        Field,
        ErrorMessage
    },
    data() {
        return {
            SEATS_MATRIX,
            showEditSynagogueModal: false,
            showAddSeatPriceModal: false,
            organizationId: null,
            seatDesign: {
                name: null
            },
            rows: 20,
            cols: 20,
            matrix: [],
            selected: [],
            selectedSynagogueSeatPriceCategory: null,
            showModal: true,
            zoomLevel: 1
        }
    },
    computed: {
        errorMessage() {
            return this.$store.getters.errorMsg
        },
        synagogueSeatPricing() {
            return this.$store.getters.synagogueSeatPricing.data
        },
        synagogueSeatPricingIsLoading() {
            return this.$store.getters.synagogueSeatPricing.isLoading
        },
        synagogue() {
            return this.$store.getters.synagogue.data
        },
        synagogueIsLoading() {
            return this.$store.getters.synagogue.isLoading
        },
        synagogueSeats() {
            return this.$store.getters.synagogueSeats.data
        },
        synagogueSeatsIsLoading() {
            return this.$store.getters.synagogueSeats.isLoading
        },
        synagogueSeatPriceCategories() {
            return this.$store.getters.synagogueSeatPriceCategories.data
        },
        synagogueSeatPriceCategoriesIsLoading() {
            return this.$store.getters.synagogueSeatPriceCategories.isLoading
        },
        zoomStyle() {
            return {
                transform: `scale(${this.zoomLevel})`,
                transformOrigin: '0% 0%', // Anchor zoom to the top-left corner
                transition: 'transform 0.3s ease'
            };
        }
    },
    methods: {
        zoomIn() {
            this.zoomLevel *= 1.1; // Increase the zoom level by 10%
        },
        zoomOut() {
            this.zoomLevel /= 1.1; // Decrease the zoom level by 10%
        },
        toggleSelection(rowIndex, colIndex) {
            if (this.selectedSynagogueSeatPriceCategory != null) {
                const index = this.selected.findIndex(item => item.row === rowIndex && item.col === colIndex);
                console.log('indexindex', index)
                if (index >= 0) {
                    this.selected[index].seatPriceCat = this.selectedSynagogueSeatPriceCategory.id
                    this.selected[index].colorHex = this.selectedSynagogueSeatPriceCategory.colorHex
                }   
            } else {
                this.$toast.info('Please select a price first.', TOAST_GLOBAL_CONFIG);
            }
        },
        isSelected(rowIndex, colIndex) {
            return this.selected.some(item => item.row === rowIndex && item.col === colIndex);
        },
        selectedColor(rowIndex, colIndex) {
            const index = this.selected.findIndex(item => item.row === rowIndex && item.col === colIndex);
            if (index >= 0) { 
                return this.selected[index].colorHex
            }
        },
        setValues() {
            this.organizationId = this.selectedSynagogue.org
        },
        generateMatrix() {
            this.matrix = []
            let value = 1;
            for (let i = 0; i < this.selectedSynagogue.rows; i++) {
                let row = [];
                for (let j = 0; j < this.selectedSynagogue.cols; j++) {
                    row.push(value);
                    value++;
                }
                this.matrix.push(row);
            }
        },
        setSelectedSeats() {
            this.selected = this.synagogueSeats.map((e) => {
                const seat = {
                    seatPriceCat: e.synagogueSeatPricing != null ? e.synagogueSeatPricing.seatPriceCat : null,
                    colorHex: e.synagogueSeatPricing != null ? e.synagogueSeatPricing.synagogueSeatPriceCategory.colorHex : null,
                    syngSeat: e.id,
                    syng: e.syng,
                    org: e.org,
                    row: e.rowIndex,
                    col: e.colIndex
                };
                return seat;
            })
        },
        selectPriceCategory(priceCategory) {
            this.selectedSynagogueSeatPriceCategory = priceCategory
        },
        addSynagogeSeatPrice() {
            this.showAddSeatPriceModal = true
        },
        editSynagogueSeats() {
            this.showEditSynagogueModal = true
        },
        getSynagogueSeatPriceCategory() {
            this.$store.dispatch(GET_SYNAGOGUE_SEAT_PRICE_CATEGORIES, { org: this.organizationId })
        },
        getSynagogueSeatPricing() {
            this.$store.dispatch(GET_SYNAGOGUE_SEAT_PRICINGS, { org: this.organizationId })
        },
        getSynagogueSeats() {
            this.generateMatrix()
            this.$store.dispatch(GET_SYNAGOGUE_SEATS, { syng: this.selectedSynagogue.id }).then((res) => {
                this.setSelectedSeats()
            })
        },
        saveSynagogueSeatPricing() {
            this.$store.dispatch(ADD_SYNAGOGUE_SEAT_PRICING, { data: this.selected.filter(item => item.seatPriceCat !== null) }).then((res) => {
                if (res) {
                    this.getSynagogueSeats()
                }
            })
        },
        getSynagogues() {
            this.$store.dispatch(ADD_SYNAGOGUE_SEAT_PRICING, { org: this.$route.params.id })
        }
    },
    mounted() {
        this.setValues()
        this.getSynagogueSeats()
        this.getSynagogueSeatPricing()
        this.getSynagogueSeatPriceCategory()
    }
}
</script>
<style scoped>
::v-deep .modal-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 98%;
    position: relative;
    left: 1%;
    margin-top: 20px;
}

::v-deep .modal-content {
    display: flex;
    flex-direction: column;
    margin: 0 1rem;
    padding: 1rem;
    padding-top: 0rem;
    padding-bottom: 0rem;
    border: 1px solid #e2e8f0;
    border-radius: 0.2rem;
    background: #fff;
    max-height: 96vh;
    overflow-y: auto;
}

::v-deep .modal__content {
    max-height: 90vh;
    overflow-y: auto;
    overflow-x: hidden;
}

#seats .row {
    width: 15000px;
    overflow-x: scroll;
}

#seats .row .col {
    max-width: 67px !important;
}

.modal__content .zoom-controls {
    position: relative;
}

.modal__content .zoom-controls .btn {
    font-size: 17px;
    color: #6489C4;
}

.modal__content .zoom-controls .btn:hover {
    color: #14243D;
}

.modal__close {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    margin-top: 0px;
    margin-right: 15px !important;
}

.modal__title {
    font-size: 18px;
    font-weight: 600;
}

.modal__close {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
}

.modal__content .nav-tabs .nav-link {
    font-size: 15px !important;
}

.modal__content .btn-add {
    float: right;
    font-size: 14px;
    font-weight: 600;
    border-radius: 8px;
    color: #14243D !important;
    padding: 0px, 14px, 0px, 14px !important;
    margin-bottom: 5px;
}

.modal__content .seat-price-dropdown .dropdown-toggle {
    font-size: 13px;
    border-radius: 8px;
    color: #14243D;
    border-radius: 8px;
    border: 1px solid transparent;
    background-color: #D9E2F1;
}

.modal__content .seat-price-dropdown .dropdown-menu {
    border-radius: 8px;
    border: 1px solid #E0E2E7;
}

.modal__content .seat-price-dropdown .dropdown-item {
    font-size: 14px;
    cursor: pointer;
}

.modal__content .seat-price-dropdown .dropdown-item:hover {
    background-color: #F5F5FB;
}

.modal__content .seat-price-dropdown .dropdown-item:active {
    color: #212529;
    background-color: #edf4ff;
}

.modal__content .btn-seat-not-selected {
    font-size: 10px;
    font-weight: 700;
    color: transparent;
    background-color: #F8F8FA;
}

.modal__content .btn-seat-selected {
    font-size: 10px;
    font-weight: 700;
    background-color: #6489C4;
    color: #FFFFFF;
    cursor: pointer
}

.modal__content .btn-seat-selected:hover {
    cursor: pointer
}

.modal__content .btn-block {
    cursor: default;
}

::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge, and Firefox */
* {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

.floor-plan-col {
    margin-left: 0px;
    padding-left: 0px;
}

hr {
    border: 1px solid #EDEDF1;
}

.btn-save {
    margin-top: 10px;
    background-color: #14243D;
}

button {
    font-size: 15px;
}
</style>