<template>
    <div class="dashboard-body">
        <div class="row">
            <sidenav class="custom-col-width-2" />
            <div style="padding-left: 0px!important" class="custom-col-width-10 content-section dashboard-content">
                <navbar />
                <registerAliasModal v-if="showAddAliasModal" :organizationId="organizationId"
                    @close-register-alias-modal="showAddAliasModal = false" @refresh-aliases="getAliases()" />
                <!-- <editMemberModal v-if="showEditMemberModal" :selectedMember="selectedMember"
                    @close-edit-member-modal="showEditMemberModal = false" @refresh-aliases="getMembers()" />
                <deleteMemberModal v-if="showDeleteMemberModal" :selectedMemberId="selectedMemberId"
                    @close-delete-member-modal="showDeleteMemberModal = false" @refresh-aliases="getMembers()" />
                <memberPurposeModal v-if="showMemberPurposeModal" :selectedMember="selectedMember"
                    @close-member-purpose-modal="showMemberPurposeModal = false" /> -->
                <div class="content">
                    <br>
                    <div class="row button-grp justify-content-between">
                        <div class="col">
                            <h4>Organization</h4>
                        </div>
                        <div class="col">
                            <button v-on:click="showAddAliasModal = true" class="btn btn-add"><i class="pi pi-plus"></i>
                                Register Alias</button>
                        </div>
                    </div>
                    <div class="row justify-content-between">
                        <organizationNavbar class="" />
                    </div>
                    <div class="row filter-grp">
                        <div class="col-lg-9 col-md-9">
                            <i class="pi pi-search" aria-hidden="true"></i>
                            <input class="form-control mr-sm-2" type="search" placeholder="Search" aria-label="Search">
                        </div>
                        <div class="col-lg-2 col-md-2">
                            <div class="row">
                                <div class="btn col-lg-7 col-md-10" style="display: block"><img
                                        src="../../assets/icons/filter-icon.svg" alt=""> Filters</div>
                            </div>
                        </div>
                    </div>
                    <div v-if="aliasesIsLoading && aliases == null">
                        <div class="row">
                            <div class="col-12 shimmer-loader-row">
                                <content-loader :speed="1" :viewBox="'0 0 100 3'" :background-color="'#FFFFFF'"
                                    :border-radius="'1px'" :foreground-color="'#DFDFFAFF'">
                                    <rect x="0" y="0" rx="0" ry="0" width="100%" height="18" />
                                </content-loader>
                            </div>
                            <br>
                            <div class="col-12 shimmer-loader-row">
                                <content-loader :speed="1" :viewBox="'0 0 100 3'" :background-color="'#FFFFFF'"
                                    :border-radius="'1px'" :foreground-color="'#F5F5FB'">
                                    <rect x="0" y="0" rx="0" ry="0" width="100%" height="18" />
                                </content-loader>
                            </div>
                            <br>
                            <div class="col-12 shimmer-loader-row">
                                <content-loader :speed="1" :viewBox="'0 0 100 3'" :background-color="'#FFFFFF'"
                                    :border-radius="'1px'" :foreground-color="'#F5F5FB'">
                                    <rect x="0" y="0" rx="0" ry="0" width="100%" height="18" />
                                </content-loader>
                            </div>
                            <br>
                            <div class="col-12 shimmer-loader-row">
                                <content-loader :speed="1" :viewBox="'0 0 100 3'" :background-color="'#FFFFFF'"
                                    :border-radius="'1px'" :foreground-color="'#F5F5FB'">
                                    <rect x="0" y="0" rx="0" ry="0" width="100%" height="18" />
                                </content-loader>
                            </div>
                            <br>
                            <div class="col-12 shimmer-loader-row">
                                <content-loader :speed="1" :viewBox="'0 0 100 3'" :background-color="'#FFFFFF'"
                                    :border-radius="'1px'" :foreground-color="'#F5F5FB'">
                                    <rect x="0" y="0" rx="0" ry="0" width="100%" height="18" />
                                </content-loader>
                            </div>
                            <br>
                            <div class="col-12 shimmer-loader-row">
                                <content-loader :speed="1" :viewBox="'0 0 100 3'" :background-color="'#FFFFFF'"
                                    :border-radius="'1px'" :foreground-color="'#F5F5FB'">
                                    <rect x="0" y="0" rx="0" ry="0" width="100%" height="18" />
                                </content-loader>
                            </div>
                            <br>
                            <div class="col-12 shimmer-loader-row">
                                <content-loader :speed="1" :viewBox="'0 0 100 3'" :background-color="'#FFFFFF'"
                                    :border-radius="'1px'" :foreground-color="'#F5F5FB'">
                                    <rect x="0" y="0" rx="0" ry="0" width="100%" height="18" />
                                </content-loader>
                            </div>
                            <br>
                            <div class="col-12 shimmer-loader-row">
                                <content-loader :speed="1" :viewBox="'0 0 100 3'" :background-color="'#FFFFFF'"
                                    :border-radius="'1px'" :foreground-color="'#F5F5FB'">
                                    <rect x="0" y="0" rx="0" ry="0" width="100%" height="18" />
                                </content-loader>
                            </div>
                            <br>
                            <div class="col-12 shimmer-loader-row">
                                <content-loader :speed="1" :viewBox="'0 0 100 3'" :background-color="'#FFFFFF'"
                                    :border-radius="'1px'" :foreground-color="'#F5F5FB'">
                                    <rect x="0" y="0" rx="0" ry="0" width="100%" height="18" />
                                </content-loader>
                            </div>
                        </div>
                    </div>
                    <div v-else class="row">
                        <div class="col-lg-12">
                            <div class="card">
                                <div class="card-body">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th scope="col">#</th>
                                                <th scope="col">parshiyot</th>
                                                <th scope="col">Unknown Field</th>
                                                <th scope="col">Offering Type</th>
                                                <th scope="col">Member (קנה)</th>
                                                <th scope="col">Paid Amount</th>
                                                <th scope="col">Member (עלה - מי שברך)</th>
                                                <th scope="col">Offered Amount</th>
                                                <th scope="col">Alias Date</th>
                                                <!-- <th scope="col">Actions</th> -->
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <template v-bind:key="index" v-for="(alias, index) in aliases">
                                                <tr :class="[isOdd(index + 1) ? 'striped' : null]">
                                                    <th scope="row">{{ index + 1 }}</th>
                                                    <td>{{ alias.parshiyot }}</td>
                                                    <td>{{ alias.unknownField }}</td>
                                                    <td>{{ alias.offeringType }}</td>
                                                    <td><span v-if="alias.memberPaid">{{ alias.memberPaid.fname }}</span></td>
                                                    <td>{{ $filters.formatPrice(alias.memberPaidAmount, 'USD') }}</td>
                                                    <td><span v-if="alias.memberOffering">{{ alias.memberOffering.fname }}</span></td>
                                                    <td>{{ $filters.formatPrice(alias.memberOfferingAmount, 'USD') }}</td>
                                                    <td>{{ $filters.getDateOnly(alias.aliasDate) }}</td>
                                                    <!-- <td>
                                                        <i v-on:click="selectAlias(alias)"
                                                            class="pi pi-pencil text-primary"></i>
                                                        <i v-on:click="selectAliasToDelete(alias.id)"
                                                            class="pi pi-trash text-danger"></i>
                                                    </td> -->
                                                </tr>
                                            </template>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br>
                    <br>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { ContentLoader } from 'vue-content-loader';

import sidenav from '@/components/Sidenav.vue'
import navbar from '@/components/Navbar.vue'
import organizationNavbar from '@/components/Organization_Navbar.vue'
import registerAliasModal from '@/components/modals/Alias/Register_Alias_Modal.vue'
import editMemberModal from '@/components/modals/Member/Edit_Member_Modal.vue'
import deleteMemberModal from '@/components/modals/Member/Delete_Member_Modal.vue'
import memberPurposeModal from '@/components/modals/Member/Member_Purpose_Modal.vue'
import { GET_ALIASES, GET_MEMBERS } from '@/store/action_types'

export default {
    title: 'Members',
    components: {
        sidenav,
        navbar,
        registerAliasModal,
        editMemberModal,
        deleteMemberModal,
        memberPurposeModal,
        organizationNavbar,
        ContentLoader
    },
    data() {
        return {
            showAddAliasModal: false,
            showEditAliasModal: false,
            showDeleteAliasModal: false,
            showAliasPurposeModal: false,
            selectedAlias: null,
            selectedAliasId: null,
            organizationId: null
        }
    },
    computed: {
        route() {
            return this.$route.name
        },
        aliases() {
            return this.$store.getters.aliases.data
        },
        aliasesIsLoading() {
            return this.$store.getters.aliases.isLoading
        },
        account() {
            return this.$store.getters.account.data
        }
    },
    methods: {
        isOdd(number) {
            return number % 2 !== 0;
        },
        selectAlias(member) {
            this.showEditAliasModal = true
            this.selectedAlias = member
        },
        selectAliasToDelete(id) {
            this.selectedAliasId = id
            this.showDeleteAliasModal = true
        },
        selectAliasPurpose(member) {
            this.selectedAlias = member
            this.showAliasPurposeModal = true
        },
        toggleAliasIsActive(id) {
            this.$store.dispatch(TOGGLE_MEMBER_IS_ACTIVE, { id })
        },
        setOrganization() {
            this.organizationId = this.$route.params.id
        },
        getAliases() {
            this.$store.dispatch(GET_ALIASES, { org: this.$route.params.id })
        }
    },
    mounted() {
        this.getAliases()
        this.setOrganization()
    }
}
</script>
<style scoped>
.content .card,
.content .card-body {
    padding: 0px;
}

.custom-col-width-2 {
    width: 16.66%;
}

.custom-col-width-10 {
    width: 83.3%;
}

@media (max-width: 1000px) {
    .custom-col-width-2 {
        width: 12%;
    }

    .custom-col-width-10 {
        width: 88%;
    }
}
</style>