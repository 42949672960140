<template>
    <div>
        <vue-final-modal :drag="false" drag-selector="" v-model="showModal" content-class="modal-content"
            classes="modal-container" focus-trap>
            <button class="modal__close btn btn-light" @click="$emit('closeAddMemberModal')">
                <i class="pi pi-times"></i>
            </button>
            <span class="modal__title">Add Member</span>
            <br>
            <div class="modal__content">
                <viewMemberTypes v-if="showViewMemberTypesModal" :organizationId="organizationId"
                    @close-view-member-types-modal="showViewMemberTypesModal = false" />
                <p v-if="errorMessage" class="text-center text-danger auth-status-msg">{{ errorMessage
                    }}</p>
                <Form @submit="addMember">
                    <div class="card">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-3">
                                    <div class="form-group">
                                        <label for="room">Title </label>
                                        <select v-model="addMemberObj.title" class="form-control" rules="required">
                                            <option>---</option>
                                            <template v-bind:key="index" v-for="(type, index) in MEMBER_TITLES">
                                                <option :value="type">{{ type }}
                                                </option>
                                            </template>
                                        </select>
                                        <ErrorMessage name="room" class="form-text text-danger" />
                                    </div>
                                </div>
                                <div class="col-3">
                                    <div class="form-group">
                                        <label for="fname">First Name</label>
                                        <Field v-model="addMemberObj.fname" rules="required" type="text" name="fname"
                                            class="form-control" id="fname" placeholder="First Name" />
                                        <ErrorMessage name="fname" class="form-text text-danger" />
                                    </div>
                                </div>
                                <div class="col-3">
                                    <div class="form-group">
                                        <label for="lname">Last Name</label>
                                        <Field v-model="addMemberObj.lname" rules="required" type="text" name="lname"
                                            class="form-control" id="lname" placeholder="Last Name" />
                                        <ErrorMessage name="lname" class="form-text text-danger" />
                                    </div>
                                </div>
                                <div class="col-3">
                                    <div class="form-group">
                                        <label for="displayName">Display Name</label>
                                        <Field v-model="addMemberObj.displayName" rules="required" type="text"
                                            name="displayName" class="form-control" id="displayName"
                                            placeholder="Display Name" />
                                        <ErrorMessage name="displayName" class="form-text text-danger" />
                                    </div>
                                </div>
                                <div class="col-3">
                                    <div class="form-group">
                                        <label for="email">Email</label>
                                        <Field v-model="addMemberObj.email" rules="required" type="email" name="email"
                                            class="form-control" id="email" placeholder="Email" />
                                        <ErrorMessage name="email" class="form-text text-danger" />
                                    </div>
                                </div>
                                <div class="col-3">
                                    <div class="form-group">
                                        <label for="phoneNumber">Phone Number</label>
                                        <Field v-model="addMemberObj.phoneNumber" rules="" type="tel" name="phoneNumber"
                                            class="form-control" id="phoneNumber" placeholder="" />
                                        <ErrorMessage name="phoneNumber" class="form-text text-danger" />
                                    </div>
                                </div>
                                <!-- <div class="form-group">
                                        <label for="type">Type <span v-on:click="showViewMemberTypesModal = true"
                                            class="badge add-new-badge text-primary">Show Types</span></label>
                                        <Field v-model="addMemberObj.type" rules="required" type="text" name="Type"
                                            class="form-control" id="type" placeholder="Type" />
                                        <ErrorMessage name="type" class="form-text text-danger" />
                                    </div> -->
                                <div class="col-3">
                                    <div class="form-group">
                                        <label for="room">Type <span v-on:click="showViewMemberTypesModal = true"
                                                class="badge add-new-badge text-primary">Show Types</span></label>
                                        <select v-model="addMemberObj.type" name="type" class="form-control"
                                            rules="required">
                                            <option>---</option>
                                            <template v-bind:key="index" v-for="(type, index) in memberTypes">
                                                <option :value="type">{{ type.name }}
                                                </option>
                                            </template>
                                        </select>
                                        <ErrorMessage name="type" class="form-text text-danger" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br>
                    <div class="card">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-3">
                                    <div class="form-group">
                                        <label for="address">Address</label>
                                        <Field v-model="addMemberObj.address" rules="required" type="text"
                                            name="address" class="form-control" id="address" placeholder="Address" />
                                        <ErrorMessage name="address" class="form-text text-danger" />
                                    </div>
                                </div>
                                <div class="col-3">
                                    <div class="form-group">
                                        <label for="city">City</label>
                                        <Field v-model="addMemberObj.city" rules="required" type="text" name="city"
                                            class="form-control" id="city" placeholder="City" />
                                        <ErrorMessage name="city" class="form-text text-danger" />
                                    </div>
                                </div>
                                <div class="col-3">
                                    <div class="form-group">
                                        <label for="state">State</label>
                                        <Field v-model="addMemberObj.state" rules="required" type="text" name="state"
                                            class="form-control" id="state" placeholder="State" />
                                        <ErrorMessage name="state" class="form-text text-danger" />
                                    </div>
                                </div>
                                <div class="col-3">
                                    <div class="form-group">
                                        <label for="postCode">Post Code</label>
                                        <Field v-model="addMemberObj.postCode" rules="" type="text" name="postCode"
                                            class="form-control" id="postCode" placeholder="-----" />
                                        <ErrorMessage name="postCode" class="form-text text-danger" />
                                    </div>
                                </div>
                                <div class="col-3">
                                    <div class="form-group">
                                        <label for="country">Country</label>
                                        <Field v-model="addMemberObj.country" rules="required" type="text"
                                            name="country" class="form-control" id="country" placeholder="Country" />
                                        <ErrorMessage name="country" class="form-text text-danger" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row justify-content-end modal__actions">
                        <button class="col-1 btn btn-save" :disabled="memberIsLoading ? true : false">
                            <span v-if="memberIsLoading" class="spinner-border spinner-border-sm" role="status"
                                aria-hidden="true"></span>
                            <span v-if="!memberIsLoading">Save</span>
                        </button>
                    </div>
                </Form>
            </div>
        </vue-final-modal>
    </div>
</template>
<script>
import { QuillEditor } from '@vueup/vue-quill';
import '@vueup/vue-quill/dist/vue-quill.snow.css';

import viewMemberTypes from '@/components/modals/Member_Type//View_Member_Types_Modal.vue'

import { ADD_MEMBER } from '@/store/action_types'
import { $vfm, VueFinalModal, ModalsContainer } from 'vue-final-modal'

import { Field, Form, ErrorMessage } from 'vee-validate';
import { defineRule } from 'vee-validate';
import { required, email, min } from '@vee-validate/rules';
import { MEMBER_TITLES } from '../../../utils/const';

defineRule('required', required);
defineRule('email', email);
defineRule('min', min);

export default {
    props: {
        organizationId: String
    },
    components: {
        VueFinalModal,
        ModalsContainer,
        Form,
        Field,
        ErrorMessage,
        QuillEditor,
        viewMemberTypes
    },
    data () {
        return {
            MEMBER_TITLES,
            showModal: true,
            addMemberObj: {
                title: null,
                fname: null,
                lname: null,
                displayName: null,
                email: null,
                phoneNumber: null,
                type: null,
                address: null,
                city: null,
                state: null,
                postCode: null,
                country: null,
                org: null
            },
            showViewMemberTypesModal: false
        }
    },
    computed: {
        errorMessage() {
            return this.$store.getters.errorMsg
        },
        member() {
              return this.$store.getters.member.data
        },
        memberIsLoading() {
            return this.$store.getters.member.isLoading
        },
        memberTypes() {
              return this.$store.getters.memberTypes.data
        },
        memberTypesIsLoading() {
            return this.$store.getters.memberTypes.isLoading
        }

    },
    methods: {
        setOrganization() {
            this.addMemberObj.org = this.organizationId
        },
        addMember() { 
            this.addMemberObj.type = this.addMemberObj.type.id
             
            this.$store.dispatch(ADD_MEMBER, this.addMemberObj).then((result) => {
                if(result) {
                    this.$emit('closeAddMemberModal')
                    this.$emit('refreshMembers')
                }
            })           
        },
    },
    created() {
        this.setOrganization()
    }
}
</script>
<style scoped>
::v-deep .modal-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
    position: relative;
    left: 10%;
    margin-top: 60px;
}

::v-deep .modal-content {
    display: flex;
    flex-direction: column;
    margin: 0 1rem;
    padding: 1rem;
    border: 1px solid #e2e8f0;
    border-radius: 0.2rem;
    background: #fff;
    max-height: 96vh;
    overflow-x: auto;
    overflow-y: auto;
}

::v-deep .modal__content {
    max-height: 90vh;
    overflow-y: auto;
    overflow-x: hidden;
}

.modal__title {
    font-size: 18px;
    font-weight: 600;
}

.modal__close {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
}

.modal__content .nav-tabs .nav-link {
    font-size: 15px!important;
}

.btn-save {
    margin-top: 10px;
    background-color: #14243D;
}

.content-row {
    margin-bottom: 8px;
}

.button-insert-var {
    font-size: 12px;
    padding: 3px 8px 3px 8px;
}

.add-new-badge {
    font-size: 10px;
}

.dropdown-item {
    font-size: 12px;
}
</style>