<template>
    <div>
        <vue-final-modal :drag="false" drag-selector="" v-model="showModal" content-class="modal-content"
            classes="modal-container" focus-trap>
            <button class="modal__close btn btn-light" @click="$emit('closeEditMemberModal')">
                <i class="pi pi-times"></i>
            </button>
            <span class="modal__title">Add Member</span>
            <br>
            <div class="modal__content">
                <div class="card">
                    <div class="card-body">
                        <p v-if="errorMessage" class="text-center text-danger auth-status-msg">{{ errorMessage }}</p>
                        <Form @submit="addMember">
                            <div class="row">
                                <div class="col-6">
                                    <div class="form-group">
                                        <label for="fname">First Name</label>
                                        <Field v-model="editMemberObj.fname" rules="required" type="text" name="fname"
                                            class="form-control" id="fname" placeholder="First Name" />
                                        <ErrorMessage name="fname" class="form-text text-danger" />
                                    </div>
                                    <div class="form-group">
                                        <label for="lname">Last Name</label>
                                        <Field v-model="editMemberObj.lname" rules="required" type="text" name="lname"
                                            class="form-control" id="lname" placeholder="Last Name" />
                                        <ErrorMessage name="lname" class="form-text text-danger" />
                                    </div>
                                    <div class="form-group">
                                        <label for="displayName">Display Name</label>
                                        <Field v-model="editMemberObj.displayName" rules="required" type="text" name="displayName"
                                            class="form-control" id="displayName" placeholder="Display Name" />
                                        <ErrorMessage name="displayName" class="form-text text-danger" />
                                    </div>
                                    <div class="form-group">
                                        <label for="email">Email</label>
                                        <Field v-model="editMemberObj.email" rules="required" type="email" name="email"
                                            class="form-control" id="email" placeholder="Email" />
                                        <ErrorMessage name="email" class="form-text text-danger" />
                                    </div>
                                    <div class="form-group">
                                        <label for="phoneNumber">Phone Number</label>
                                        <Field v-model="editMemberObj.phoneNumber" rules="" type="tel"
                                            name="phoneNumber" class="form-control" id="phoneNumber" placeholder="" />
                                        <ErrorMessage name="phoneNumber" class="form-text text-danger" />
                                    </div>
                                    <div class="form-group">
                                        <label for="type">Type</label>
                                        <Field v-model="editMemberObj.type" rules="required" type="text" name="Type"
                                            class="form-control" id="type" placeholder="Type" />
                                        <ErrorMessage name="type" class="form-text text-danger" />
                                    </div>
                                    <hr>
                                </div>
                                <div class="col-6">
                                    <div class="form-group">
                                        <label for="address">Address</label>
                                        <Field v-model="editMemberObj.address" rules="required" type="text" name="address"
                                            class="form-control" id="address" placeholder="Address"/>
                                        <ErrorMessage name="address" class="form-text text-danger" />
                                    </div>
                                    <div class="form-group">
                                        <label for="city">City</label>
                                        <Field v-model="editMemberObj.city" rules="required" type="text" name="city"
                                            class="form-control" id="city" placeholder="City" />
                                        <ErrorMessage name="city" class="form-text text-danger" />
                                    </div>
                                    <div class="form-group">
                                        <label for="state">State</label>
                                        <Field v-model="editMemberObj.state" rules="required" type="text" name="state"
                                            class="form-control" id="state" placeholder="State" />
                                        <ErrorMessage name="state" class="form-text text-danger" />
                                    </div>
                                    <div class="form-group">
                                        <label for="postCode">Post Code</label>
                                        <Field v-model="editMemberObj.postCode" rules="" type="text" name="postCode"
                                            class="form-control" id="postCode" placeholder="-----" />
                                        <ErrorMessage name="postCode" class="form-text text-danger" />
                                    </div>
                                    <div class="form-group">
                                        <label for="country">Country</label>
                                        <Field v-model="editMemberObj.country" rules="required" type="text"
                                            name="country" class="form-control" id="country" placeholder="Country" />
                                        <ErrorMessage name="country" class="form-text text-danger" />
                                    </div>
                                </div>
                            </div>
                            <div class="row justify-content-end modal__actions">
                                <button class="col-12 btn btn-save" :disabled="memberIsLoading ? true : false">
                                    <span v-if="memberIsLoading" class="spinner-border spinner-border-sm"
                                        role="status" aria-hidden="true"></span>
                                    <span v-if="!memberIsLoading">Save</span>
                                </button>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </vue-final-modal>
    </div>
</template>
<script>
import { QuillEditor } from '@vueup/vue-quill';
import '@vueup/vue-quill/dist/vue-quill.snow.css';

import { EDIT_MEMBER } from '@/store/action_types'
import { $vfm, VueFinalModal, ModalsContainer } from 'vue-final-modal'

import { Field, Form, ErrorMessage } from 'vee-validate';
import { defineRule } from 'vee-validate';
import { required, email, min } from '@vee-validate/rules';

defineRule('required', required);
defineRule('email', email);
defineRule('min', min);

export default {
    props: {
        selectedMember: Object
    },
    components: {
        VueFinalModal,
        ModalsContainer,
        Form,
        Field,
        ErrorMessage,
        QuillEditor
    },
    data () {
        return {
            showModal: true,
            editMemberObj: {
                id: null,
                fname: null,
                lname: null,
                displayName: null,
                email: null,
                phoneNumber: null,
                type: null,
                address: null,
                city: null,
                state: null,
                postCode: null,
                country: null
            }
        }
    },
    computed: {
        errorMessage() {
            return this.$store.getters.errorMsg
        },
        member() {
              return this.$store.getters.member.data
        },
        memberIsLoading() {
            return this.$store.getters.member.isLoading
        }
    },
    methods: {
        setValues() {
            this.editMemberObj.id = this.selectedMember.id
            this.editMemberObj.fname = this.selectedMember.fname
            this.editMemberObj.lname = this.selectedMember.lname
            this.editMemberObj.displayName = this.selectedMember.displayName
            this.editMemberObj.email = this.selectedMember.email
            this.editMemberObj.phoneNumber = this.selectedMember.phoneNumber
            this.editMemberObj.type = this.selectedMember.type
            this.editMemberObj.address = this.selectedMember.address
            this.editMemberObj.city = this.selectedMember.city
            this.editMemberObj.state = this.selectedMember.state
            this.editMemberObj.postCode = this.selectedMember.postCode
            this.editMemberObj.country = this.selectedMember.postCode
        },
        addMember() {   
            this.$store.dispatch(EDIT_MEMBER, this.editMemberObj).then((result) => {
                if(result) {
                    this.$emit('closeEditMemberModal')
                    this.$emit('refreshMembers')
                }
            })           
        },
    },
    created() {
        this.setValues()
    }
}
</script>
<style scoped>
::v-deep .modal-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60%;
    position: relative;
    left: 20%;
    margin-top: 60px;
}

::v-deep .modal-content {
    display: flex;
    flex-direction: column;
    margin: 0 1rem;
    padding: 1rem;
    border: 1px solid #e2e8f0;
    border-radius: 0.2rem;
    background: #fff;
}

.modal__title {
    font-size: 18px;
    font-weight: 600;
}

.modal__close {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
}

.modal__content .nav-tabs .nav-link {
    font-size: 15px!important;
}

.btn-close {
    border-radius: 10px;
    font-size: 13px;
    font-weight: 600;
    width: 75%;
    background-color: #CAB781;

}

.btn-save {
    margin-top: 10px;
    background-color: #14243D;
}

.appointment-card {
    margin-top: 5px;
}

.appointment-card .card-body {
    padding: 0px 5px 0px 5px;
}

.appointment-card .customer {
    font-size: 15px;
}
.appointment-card .badge {
    margin-left: 10px;
    margin-right: 5px;
}

label {
    font-size: 13px;
}

input {
    height: 35px!important;
    font-size: 13px;
}

input::placeholder {
    font-size: 13px;
}

select {
    height: 35px!important;
    font-size: 13px;
}

select::placeholder {
    font-size: 13px;
}
.form-check {
    position: relative;
    display: block;
    margin-top: 25px;
    margin-bottom: 0.75rem;
    padding-left: 0px;
  }
  
  .form-check-toggle {
    position: relative;
    padding-left: 0;
    line-height: 30px;
  }
  .form-check-toggle input {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 0%;
    height: 0%;
    margin: 0;
    cursor: pointer;
    opacity: 0;
    filter: alpha(opacity=0);
  }
  .form-check-toggle input + span {
    cursor: pointer;
    member-select: none;
    height: 30px;
    margin-left: 70px;
    display: block;
  }
  .form-check-toggle input + span:before {
    content: "";
    position: absolute;
    left: 0;
    display: inline-block;
    height: 30px;
    width: 50px;
    background: #F0F5FC;
    border: solid 1px #D4D3D3;
    transition: background 0.3s ease-in-out, border-color 0.3s ease-in-out;
    border-radius: 7px;
  }
  .form-check-toggle input + span:after {
    width: 28px;
    height: 28px;
    margin-top: 1px;
    margin-left: 1px;
    border-radius: 50%;
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    background: #fff;
    transition: margin-left 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    text-align: center;
    font-weight: bold;
    content: "";
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.2), 0 0 5px rgba(0, 0, 0, 0.05);
  }
  .form-check-toggle input:checked + span:after {
    content: "";
    margin-left: 21px;
    box-shadow: none;
  }
  .form-check-toggle input:checked + span:before {
    background-color: #CAB781;
    border-color: #CAB781;
    transition: background 0.3s ease-in-out, border-color 0.3s ease-in-out;
  }

  .content-row {
    margin-bottom: 8px;
  }

  .button-insert-var {
    font-size: 12px;
    padding: 3px 8px 3px 8px;
  }

  .dropdown-item {
    font-size: 12px;
  }
</style>