<template>
    <div>
        <vue-final-modal :drag="false" drag-selector="" v-model="showModal" content-class="modal-content"
            classes="modal-container" focus-trap>
            <button class="modal__close btn btn-light" @click="$emit('closeAddSeatPledgeModal')">
                <i class="pi pi-times"></i>
            </button>
            <span class="modal__title">Register Member Seats Pledge</span>
            <br>
            <div class="modal__content">
                <JewishDatePicker v-if="showJewishDatePicker" @select-date="selectPledgeItemPledgeDate"
                    @close-date-picker-modal="closeModal()" />
                <viewPledgeTypeModal v-if="showViewPledgeTypeModal"
                    @close-view-pledge-types-modal="showViewPledgeTypeModal = false" />
                <div class="row">
                    <div class="col-12">
                        <p v-if="errorMessage" class="text-center text-danger auth-status-msg">{{ errorMessage }}</p>
                        <Form @submit="registerPledge">
                            <div class="card">
                                pledgeItems- {{ pledgeItems }}
                                <div class="card-body">
                                    <h5 class="title">Items</h5>
                                    <div class="row">
                                        <div class="col-10">
                                            <div class="row">
                                                <div class="col-3">
                                                    <div class="form-group">
                                                        <label for="pldgType">Type <span
                                                                v-on:click="showViewPledgeTypeModal = true"
                                                                class="badge add-new-badge text-primary">Show
                                                                Types</span></label>
                                                    </div>
                                                </div>
                                                <div class="col-3">
                                                    <div class="form-group">
                                                        <label for="price">Unit Price</label>
                                                    </div>
                                                </div>
                                                <div class="col-3">
                                                    <div class="form-group">
                                                        <label for="quatity">Quantity</label>
                                                    </div>
                                                </div>
                                                <div class="col-3">
                                                    <div class="form-group">
                                                        <label for="pledgeDate">Pledge Date</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-2">
                                            <div class="form-group">
                                                <label for="amount">Total</label>
                                            </div>
                                        </div>
                                    </div>
                                    <template v-bind:key="index" v-for="(item, index) in pledgeItems">
                                        <div v-if="item" class="row invoice-item">
                                            <div class="col-10">
                                                <div class="row">
                                                    <div class="col-3">
                                                        <div class="form-group">
                                                            <Field type="text" class="form-control disabled"
                                                                :name="`pledgeType${index}`"
                                                                v-model="pledgeItems[index].pldgType" rules="required"
                                                                style="display:none;" />
                                                            <select v-model="pledgeItems[index].pldgType"
                                                                rules="required" class="form-control">
                                                                <template v-for="(type, id) in pledgeTypes" :key="id">
                                                                    <option :value="type">{{ type.name }}</option>
                                                                </template>
                                                            </select>
                                                            <ErrorMessage :name="`pledgeType${index}`"
                                                                class="form-text text-danger" />
                                                        </div>
                                                    </div>
                                                    <div class="col-3">
                                                        <div class="form-group">
                                                            <!-- <label for="amount">Unit Price</label> -->
                                                            <Field v-model="pledgeItems[index].price" rules="required"
                                                                type="number" min="0.1" step="0.01"
                                                                :name="`invoiceItemPrice${index}`" class="form-control"
                                                                id="amount" placeholder="0.00" />
                                                            <ErrorMessage :name="`invoiceItemPrice${index}`"
                                                                class="form-text text-danger" />
                                                        </div>
                                                    </div>
                                                    <div class="col-3">
                                                        <div class="form-group">
                                                            <!-- <label for="amount">Quantity</label> -->
                                                            <Field v-model="pledgeItems[index].quantity"
                                                                rules="required" type="number" min="1" step="1"
                                                                :name="`invoiceItemQuantity${index}`"
                                                                class="form-control" id="amount" placeholder="0" />
                                                            <ErrorMessage :name="`invoiceItemQuantity${index}`"
                                                                class="form-text text-danger" />
                                                        </div>
                                                    </div>
                                                    <div class="col-3">
                                                        <div @click="showPledgeItemDueDatePicker(index)"
                                                            class="form-group">
                                                            <!-- <label for="donationDisplayEndDate">Display End Date</label> -->
                                                            <div class="row date-picker-row">
                                                                <div class="col-10">
                                                                    <Field v-model="pledgeItems[index].hebrewPledgeDate"
                                                                        rules="required" disabled type="text"
                                                                        :name="`pledgeDate${index}`"
                                                                        class="form-control" id="itemDueDate"
                                                                        placeholder="Pledge Date" />
                                                                </div>
                                                                <div class="col-2">
                                                                    <i class="pi pi-calendar-plus text-primary"></i>
                                                                </div>
                                                            </div>
                                                            <ErrorMessage :name="`pledgeDate${index}`"
                                                                class="form-text text-danger" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-1">
                                                <small>{{ $filters.formatPrice(pledgeItems[index].price *
                                                    pledgeItems[index].quantity, newPledgeObj.currency ?? 'USD')
                                                    }}</small>
                                            </div>
                                            <div v-if="pledgeItems.length > 1" class="col">
                                                <a v-on:click="deletePledgeItem(index)"
                                                    class="btn btn-link btn-delete-invoice-item"><i
                                                        class="pi pi-trash text-danger"></i></a>
                                            </div>
                                        </div>
                                    </template>
                                    <div class="row justify-content-start">
                                        <div class="col-1">
                                            <div class="form-check custom-checkbox">
                                                <label class="form-check-label" for="isPaid">
                                                    <Field v-model="newPledgeObj.isPaid" :value="true" type="checkbox"
                                                        class="form-check-input" id="isPaid" name="isPaid" />
                                                    <span class="checkmark"></span>
                                                    Paid
                                                </label>
                                                <ErrorMessage name="useCredit" class="form-text text-danger" />
                                            </div>
                                        </div>
                                        <div class="col">
                                            <div class="form-check custom-checkbox">
                                                <label class="form-check-label" for="useCredit">
                                                    <Field v-model="newPledgeObj.useCredit" :value="true"
                                                        type="checkbox" class="form-check-input" id="useCredit"
                                                        name="useCredit" />
                                                    <span class="checkmark"></span>
                                                    Deduct from credit
                                                </label>
                                                <ErrorMessage name="useCredit" class="form-text text-danger" />
                                            </div>
                                        </div>
                                    </div>
                                    <!-- <a v-on:click="addPledgeItem()" class="btn btn-add-new-item">Add New Item</a> -->
                                    <hr v-if="newPledgeObj.isPaid">
                                    <div class="row">
                                        <div v-if="newPledgeObj.isPaid" class="col-3">
                                            <div class="form-group">
                                                <label for="paymentMethod">Payment Method</label>
                                                <select v-model="newPledgeObj.paymentMethod" class="form-control"
                                                    rules="required">
                                                    <option>---</option>
                                                    <template v-bind:key="index"
                                                        v-for="(payment, index) in PAYMENT_METHODS">
                                                        <option :value="payment">{{ payment }}</option>
                                                    </template>
                                                </select>
                                                <ErrorMessage name="paymentMethod" class="form-text text-danger" />
                                            </div>
                                        </div>
                                        <div v-if="newPledgeObj.isPaid && newPledgeObj.paymentMethod == PAYMENT_METHODS[3]"
                                            class="col-3">
                                            <div class="form-group">
                                                <label for="checkNumber">Check Number</label>
                                                <Field v-model="newPledgeObj.checkNumber" rules="required" type="text"
                                                    name="checkNumber" class="form-control" id="checkNumber"
                                                    placeholder="##########" />
                                                <ErrorMessage name="checkNumber" class="form-text text-danger" />
                                            </div>
                                        </div>
                                        <div v-if="newPledgeObj.isPaid && newPledgeObj.paymentMethod == PAYMENT_METHODS[4]"
                                            class="col-3">
                                            <div class="form-group">
                                                <label for="cardOptions">Charity Card Option</label>
                                                <select v-model="newPledgeObj.charityCardOption" class="form-control"
                                                    rules="required">
                                                    <option>---</option>
                                                    <template v-bind:key="index"
                                                        v-for="(cardOptions, index) in CHARITY_CARD_OPTIONS">
                                                        <option :value="cardOptions">{{ cardOptions }}</option>
                                                    </template>
                                                </select>
                                                <ErrorMessage name="cardOptions" class="form-text text-danger" />
                                            </div>
                                        </div>
                                        <div v-if="newPledgeObj.isPaid && newPledgeObj.paymentMethod == PAYMENT_METHODS[4] && newPledgeObj.charityCardOption == CHARITY_CARD_OPTIONS[4]"
                                            class="col-4">
                                            <div class="form-group">
                                                <label for="charityCardOptionOther">Charity Card Option Other</label>
                                                <Field v-model="charityCardOptionOther" rules="required" type="text"
                                                    name="charityCardOptionOther" class="form-control"
                                                    id="charityCardOptionOther" placeholder="Type here" />
                                                <ErrorMessage name="charityCardOptionOther"
                                                    class="form-text text-danger" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br>
                            <div class="row justify-content-end modal__actions">
                                <button v-on:click="closePledgeModal()" class="col-1 btn btn-close">
                                    <img src="../../../../assets/icons/close-icon.svg" alt=""> Cancel
                                </button>
                                <button type="submit" class="col-1 btn btn-save"
                                    :disabled="synagogueMemberSeatIsLoading ? true : false">
                                    <span v-if="synagogueMemberSeatIsLoading" class="spinner-border spinner-border-sm"
                                        role="status" aria-hidden="true"></span>
                                    <span v-if="!synagogueMemberSeatIsLoading"><img
                                            src="../../../../assets/icons/save-icon.svg" alt=""> Save</span>
                                </button>
                            </div>
                        </Form>
                    </div>
                    <!-- <div class="col-6">
                        <br>
                        <div class="card">
                            <div class="card-body">
                                <span v-html="renderContent(newPledgeObj.title, newPledgeObj)"></span>
                            </div>
                        </div>
                    </div> -->
                </div>
                <br>
            </div>
        </vue-final-modal>
    </div>
</template>
<script>
import JewishDatePicker from '@/components/JewishDatePicker.vue';
import viewPledgeTypeModal from '@/components/modals/Pledge_Type/View_Pledge_Types_Modal.vue';

import { ADD_SYNAGOGUE_MEMBER_SEAT, GET_MEMBERS, GET_PLEDGE_TYPES } from '@/store/action_types'
import { $vfm, VueFinalModal, ModalsContainer } from 'vue-final-modal'

import { Field, Form, defineRule, ErrorMessage } from 'vee-validate';
import { required, email, min } from '@vee-validate/rules';
import { DATE_PICKER_DONATION_DATE, DATE_PICKER_DONATION_DISPLAY_START_DATE, DATE_PICKER_INVOICE_ITEM_DUE_DATE, PAYMENT_METHODS, CHARITY_CARD_OPTIONS, CURRENCIES } from '@/utils/const';

defineRule('required', required);
defineRule('email', email);
defineRule('min', min);

export default {
    props: {
        organizationId: String,
        memberId: String,
        hasCredit: Boolean,
        memberSeats: Array
    },
    components: {
        JewishDatePicker,
        viewPledgeTypeModal,
        VueFinalModal,
        ModalsContainer,
        Form,
        Field,
        ErrorMessage
    },
    data() {
        return {
            DATE_PICKER_DONATION_DATE,
            DATE_PICKER_DONATION_DISPLAY_START_DATE,
            DATE_PICKER_INVOICE_ITEM_DUE_DATE,
            CURRENCIES,
            PAYMENT_METHODS,
            CHARITY_CARD_OPTIONS,
            selectedPledgeItemIndex: 0,
            showJewishDatePicker: false,
            showViewPledgeTypeModal: false,
            showModal: true,
            showAddMemberModal: false,
            newPledgeObj: {
                pledgeItems: [],
                memb: null,
                org: null,
                useCredit: true,
                isPaid: false
            },
            pledgeItems: [{
                pldgType: null,
                quantity: 1,
                price: null,
                pledgeDate: null,
                hebrewPledgeDate: null
            }],
            memberSeatsObj: {
                memberSeats: null,
                newPledgeObj: null
            }
        }
    },
    computed: {
        errorMessage() {
            return this.$store.getters.errorMsg
        },
        account() {
            return this.$store.getters.account.data
        },
        invoice() {
            return this.$store.getters.invoice.data
        },
        invoiceIsLoading() {
            return this.$store.getters.invoice.isLoading
        },
        pledgeItem() {
            return this.$store.getters.pledgeItem.data
        },
        pledgeItemIsLoading() {
            return this.$store.getters.pledgeItem.isLoading
        },
        pledgeTypes() {
            return this.$store.getters.pledgeTypes.data
        },
        pledgeTypesIsLoading() {
            return this.$store.getters.pledgeTypes.isLoading
        },
        synagogueMemberSeat() {
            return this.$store.getters.synagogueMemberSeat.data
        },
        synagogueMemberSeatIsLoading() {
            return this.$store.getters.synagogueMemberSeat.isLoading
        },
        account() {
            return this.$store.getters.account.data
        },
    },
    methods: {
        synchExpirationMonthUI(index) {
            this.pledgeItems[index].pldgType = this.expirationMonthUI;
        },
        showPledgeItemDueDatePicker(index) {
            this.selectedPledgeItemIndex = index
            this.showJewishDatePicker = true
        },
        selectPledgeItemPledgeDate(selectedDate) {
            // this.pledgeItems[selectedPledgeItemIndex].pledgeDate = this.$filters.convertHDateToGregorian(selectedDate)
            this.pledgeItems[this.selectedPledgeItemIndex].pledgeDate = this.$filters.convertHDateToGregorian(selectedDate)
            this.pledgeItems[this.selectedPledgeItemIndex].hebrewPledgeDate = this.$filters.convertToHebrewDate(this.pledgeItems[this.selectedPledgeItemIndex].pledgeDate)
        },
        addPledgeItem() {
            let now = new Date()
            this.pledgeItems.push({
                pldgType: null,
                quantity: 1,
                price: null,
                pledgeDate: now,
                hebrewPledgeDate: this.$filters.convertToHebrewDate(now)
            })
        },
        deletePledgeItem(index) {
            if (this.pledgeItems.length > 1) {
                this.pledgeItems.splice(index, 1)
            }
        },
        setValues() {
            this.newPledgeObj.org = this.$route.params.orgId ?? this.account.org
            this.newPledgeObj.memb = this.$route.params.membId
            this.newPledgeObj.useCredit = this.hasCredit
            this.pledgeItems = []
            let now = new Date()
            this.memberSeats.forEach((e) => {
                if (e.isNew == true) {
                    this.pledgeItems.push({
                        pldgType: null,
                        quantity: 1,
                        price: e.price,
                        pledgeDate: now,
                        hebrewPledgeDate: this.$filters.convertToHebrewDate(now)
                    })
                }
            })
        },
        closeModal() {
            this.showJewishDatePicker = false;
        },
        closePledgeModal() {
            this.$emit('closeAddSeatPledgeModal')
        },
        caluclateTotalAmount() {
            return this.pledgeItems.reduce((total, item) => {
                return total + (item.price * item.quantity);
            }, 0);
        },
        getPledgeTypes() {
            this.$store.dispatch(GET_PLEDGE_TYPES, { org: this.organizationId ?? this.account.org })
        },
        registerPledge() {
            this.newPledgeObj.pledgeItems = this.pledgeItems.map((e) => {
                const updatedItem = {
                    ...e,
                    pldgType: e.pldgType.id,
                    // pledgeDate: this.$filters.convertHDateToGregorian(e.pledgeDate)
                };
                return updatedItem;
            })

            // if (this.memberId == null) {
            //     this.newPledgeObj.memb = this.newPledgeObj.memb.id
            // }
            this.memberSeatsObj.memberSeats = this.memberSeats
            this.memberSeatsObj.newPledgeObj = this.newPledgeObj

            this.$store.dispatch(ADD_SYNAGOGUE_MEMBER_SEAT, this.memberSeatsObj).then((result) => {
                if (result) {
                    this.$emit('closeAddSeatPledgeModal')
                    this.$emit('refreshMemberSeats')
                }
            })
        }
    },
    created() {
        defineRule('requiredObject', value => {
            console.log('asfasf444%%', value)
            return value !== null && typeof value === 'object'; // Validate it's a non-null object
        });

        this.setValues()
        this.getPledgeTypes()
    }
}
</script>
<style scoped>
::v-deep .modal-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90%;
    position: relative;
    left: 5%;
    margin-top: 50px;
}

::v-deep .modal-content {
    display: flex;
    flex-direction: column;
    margin: 0 1rem;
    padding: 1rem;
    border: 1px solid #e2e8f0;
    border-radius: 0.2rem;
    background: #fff;
}

.modal__content .nav-tabs .nav-link {
    font-size: 15px !important;
}

.btn-add-new-item {
    border-radius: 8px;
    font-size: 13px;
    margin-top: 10px;
    color: #14243D !important;
    background-color: transparent;
    border: 1px solid #14243D;
}

.invoice-item {
    height: 50px;
    margin-top: 0px;
    margin-bottom: 0px;
}

.add-new-badge {
    font-size: 10px;
}

.btn-delete-invoice-item {
    font-size: 15px;
}

.form-check {
    padding-left: 0px;
    margin-top: 0px;
    margin-bottom: 10px;
    position: relative;
    top: 5px;
    cursor: pointer;
}

.form-check .form-check-input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.form-check .form-check-label {
    cursor: pointer;
}


.custom-checkbox .checkmark {
    position: relative;
    top: 3px;
    height: 18px;
    width: 18px;
    background-color: #F9F9FC;
    border: 1px solid #cfd3dd;
    border-radius: 4px;
    display: inline-block;
    margin-right: 10px;

}

.custom-checkbox .form-check-input:checked+.checkmark {
    background-color: #14243D;
}

.custom-checkbox .checkmark::after {
    content: "";
    position: absolute;
    display: none;
}

.custom-checkbox .form-check-input:checked+.checkmark::after {
    display: block;
}

.custom-checkbox .checkmark::after {
    left: 6px;
    top: 3px;
    width: 4px;
    height: 8px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}
</style>